<template>
<div>
    <div class="formwork">
        <Nav />
        <!-- 上方海报 -->
        <div class="poster bg4">
            <div class="back" @click="back">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/返回.png" alt="">
                <h2>
                    返回案例
                </h2>
            </div>
            <el-button @click="consult">立即咨询</el-button>
        </div>
        <div class="main">
            <div class="bground">
                <h2>项目背景</h2>
                <p> 本项目是蓉江新区建设智慧城市工程子项目之一，以区块链技术为基础技术平台，构建“资产数字化，数字可信化”，实现蓉江新区各项业务开展的可信协作和可防可控，针对业务授权、数据授权和系统授权做到精细化、精准化过程管控，实现对数据和业务的历史追踪溯源，实现各项业务开展的事前审核、事中监控、事后追溯的全方位感知体系。</p>
            </div>
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <div class="painPoint">
                <h2>项目痛点</h2>
                <p> 数字资产及固有资产管理体系还不健全，信息化建设还不理想，
                    <br>
                    数字资产及固有资产数量庞大，管理方法还未明确，希望能深化到资产对生命周期管理
                    <br>
                    现有数据存储技术容易被篡改，数据的安全可靠性不高</p>
            </div>
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <div class="solve">
                <h2>解决方案</h2>
                <p> 通过对智慧城市领域的缺乏信任、多方协作、数据资产化、资金监管等可信服务场景的规划，解决智慧城市发展过程中产生的可信问题，为智慧城市的发展进行充分赋能，平台本身基于区块链+实际业务进行全量规划设计，具备可信、不可篡改、可溯源、可统一监管等特色。
                    <br>
                    针对业务授权、数据授权和系统授权做到精细化、精准化过程管控，实现对数据和业务的历史追踪溯源，实现各项业务开展的事前审核、事中监控、事后追溯的全方位感知体系。</p>
                <img class="computer" src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/智慧城市-区块链可信服务.png" alt="">
            </div>
            <div class="achievement">
                <h2>项目成就</h2>
                <p> 实现城市数字资产及固定资产数据完整性保护，使得数据价值得到保护和认证
                    <br>
                    实现城市数字资产及固定资产业务的整合，从资产的申领、使用、维修等全生命周期监控。
                    <br>
                    实现城市数字资产及固定资产数据的可信、可追溯。保证数据提供者的权益。</p>
            </div>
        </div>
    </div>

    <!-- <Side /> -->

    <Footer ref="Footer" />
</div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Nav from "@/components/nav.vue";
import Side from "@/components/side.vue";
export default {
    components: {
        Footer,
        Nav,
        Side
    },
    mounted() {
        this.showWhat = sessionStorage.getItem("id")
        window.scrollTo(0, 0)
    },
    data() {
        return {
            showWhat: null
        }
    },
    methods: {
        back() {
            this.$router.push("/case")
        },
        consult() {
            this.$refs.Footer.checkShow(2)
        }
    }
}
</script>

<style lang="scss" scoped>
.formwork {
    display: flex;
    flex-direction: column;
    align-items: center;
}

//   上方海报
.poster {
    position: relative;
    width: 100%;
    height: 620px;
    background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/区块链banner2.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-bottom: 50px;

    .back {
        display: flex;
        align-items: center;
        padding: 20px 0 0 150px;
        cursor: pointer;

        img {
            width: 30px;
            height: 30px;
            font-size: 50px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 55px;
            margin-right: 10px;
        }

        h2 {
            font-size: 29px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 55px;
        }
    }

    .el-button {
        position: absolute;
        top: 500px;
        left: 180px;
        width: 203px;
        height: 50px;
        background: linear-gradient(0deg, #F28E26, #FD644F);
        font-size: 25px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
        border-radius: 40px;
        border: none;
    }
}

.main {
    width: 90%;

    >div {
        text-align: left;
        margin-bottom: 110px;

        h2 {
            padding-right: 50px;
            box-sizing: border-box;
            text-align: center;
            width: 260px;
            height: 80px;
            background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/框.png);
            background-size: 100%;
            background-repeat: no-repeat;
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #202020;
            line-height: 80px;
            margin-bottom: 40px;
        }

        p {
            width: 65%;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 31px;
            padding-left: 50px;
            box-sizing: border-box;
        }
    }

    .list {
        margin-top: 45px;

        div {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            img {
                width: 30px;
                height: 30px;
                margin-right: 10px;
            }

            h3 {
                padding-top: 5px;
                font-size: 21px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #202020;
                line-height: 30px;
                box-sizing: border-box;
            }

        }

        ul {
            padding-left: 60px;

            li {
                width: 50%;
                list-style-type: decimal;
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #666666;
                line-height: 33px;
            }
        }

    }

    .computer {
        width: 530px;
        height: 434px;
        margin: 30px 0 0 60px;
    }
}
</style>
