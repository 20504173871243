// 组件使用说明：
// 1、作用：含有缩略图和主图的组件
// 2、传入参数：这里为了看效果，暂时没写props传参，有不理解的可以搜索关于自定义组件中props的用法
<template>
<div class="thumb-example">
    <!-- swiper1 -->
    <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
        <swiper-slide class="slide-1 swiper-no-swiping"></swiper-slide>
        <swiper-slide class="slide-2 swiper-no-swiping"></swiper-slide>
        <swiper-slide class="slide-3 swiper-no-swiping"></swiper-slide>
        <swiper-slide class="slide-4 swiper-no-swiping"></swiper-slide>
        <swiper-slide class="slide-5 swiper-no-swiping"></swiper-slide>
        <swiper-slide class="slide-6 swiper-no-swiping"></swiper-slide>
        <swiper-slide class="slide-7 swiper-no-swiping"></swiper-slide>
        <div class="swiper-button-next swiper-button-white" slot="button-next" @click="changeNum('next')"></div>
        <div class="swiper-button-prev swiper-button-white" slot="button-prev" @click="changeNum('prev')"></div>
    </swiper>
    <div class="itemInfo" v-if="actIndex == 1">
        <h3>情指一体化</h3>
        <p>
            基于NPGIS地理引擎及公安局的实际应用需求，结合大数据应用，在110接处警系统，视频监控系统、卡口大数据系统、无人机系统、智能识别系统等业务系统的基础上建设的一体化管控平台。
        </p>
    </div>
    <div class="itemInfo" v-if="actIndex == 2">
        <h3>卡口布控</h3>
        <p>
            基于先进的光电技术、图像处理技术、模式识别技术对过往的每一辆汽车均拍下车辆的图像，并自动识别出车辆的牌照，所采集到的车辆的信息数据均保存在服务器数据库中。
        </p>
    </div>
    <div class="itemInfo" v-if="actIndex == 3">
        <h3>图码联侦</h3>
        <p>
            集⼿机MAC/IMSI、⼈像、车牌信息数据采集于⼀体，进⾏优势互补，实现地图轨迹分析、陌⽣⼈员摸排、关系挖掘、伴随分析、布控告警及关注⼈员管控等业务功能。前端感知设备采集各类数据通过数据管理交换平台进⾏整理汇总传输⾄⼤数据中⼼。
        </p>
    </div>
    <div class="itemInfo" v-if="actIndex == 4">
        <h3>智慧警务</h3>
        <p>
            结合警务人员的具体工作及实际需求，依托人工智能、大数据、区块链、5G和互联网等技术，为警务业务提供方便、快捷、科学的信息化管理。实现警务业务覆盖，提高部门人员工作效率，规范警务业务管理流程，推动数字化警务的进一步建设。
        </p>
    </div>
    <div class="itemInfo" v-if="actIndex == 5">
        <h3>电子围栏</h3>
        <p>
            针对手机实现无线数据采集终端，利用先进的无线移动网络和电子信息技术，能够对特定的区域或指定的人员进行精确管控。实现掌握城市往来人群中关键人员的活动轨迹和过往记录；了解特殊人群中的个体和群体的反常活动；能在特定范围内进行无线屏蔽、实现通讯管制；公安电子围栏的使用，大大提高了公安对特定人群和区域进行的有效管理。
        </p>
    </div>
    <div class="itemInfo" v-if="actIndex == 6">
        <h3>协同办公</h3>
        <p>
            提高团队工作有效性，实现知识的有效积累，缩短内部流程运转周期，提高内部流程运作质量，提高对内部流程的监督能力作为基准，达到业务流程规范化，管理精细化，资源一体化，数据集中化，决策科学化，办公协同化。
        </p>
    </div>
    <div class="itemInfo" v-if="actIndex == 7">
        <h3>智慧消防</h3>
        <p>
            利用物联网、人工智能、GIS等现代技术，配合大数据云计算平台、火警智能研判等专业应用，大力创新消防管理模式，开展智慧防控、作战、执法和管理，对执法工作的系统化管理以及对管理运营的精细化管理。实现城市消防的智慧化，提高信息传递的效率、保障消防设施的完好率、改善执法及管理效果、增强救援能力、降低火灾发生及损失。
        </p>
    </div>
    <!-- swiper2 Thumbs -->
    <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
        <swiper-slide class="slide-1 swiper-no-swiping"></swiper-slide>
        <swiper-slide class="slide-2 swiper-no-swiping"></swiper-slide>
        <swiper-slide class="slide-3 swiper-no-swiping"></swiper-slide>
        <swiper-slide class="slide-4 swiper-no-swiping"></swiper-slide>
        <swiper-slide class="slide-5 swiper-no-swiping"></swiper-slide>
        <swiper-slide class="slide-6 swiper-no-swiping"></swiper-slide>
        <swiper-slide class="slide-7 swiper-no-swiping"></swiper-slide>
    </swiper>
</div>
</template>

<script>
import {
    Swiper,
    SwiperSlide
} from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
    name: 'swiper-example-thumbs-gallery',
    title: 'Thumbs gallery with Two-way control',
    components: {
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            actIndex: 1,

            swiperOptionTop: {
                loop: true,
                loopedSlides: 5, // looped slides should be the same
                spaceBetween: 10,
                noSwiping: true,
                // 左右两边的点击事件
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            swiperOptionThumbs: {
                loop: true, // 是否可循环
                loopedSlides: 5, // looped slides should be the same
                spaceBetween: 10, // 缩略图之间的间隙大小
                centeredSlides: true, // 大图对应的缩略图位置显示在中间
                slidesPerView: 5, // 每一页显示下方缩略图的数量，auto ：自动显示所有数量；输入number（如1、2、3等）则是手动定义显示的数量
                touchRatio: 0.2, // 触控比例，可理解为拖动缩略图的距离，默认值为1
                slideToClickedSlide: false, //点击其他缩略图可跳转
                noSwiping: true, //不可拖拽切换(配合类名swiper-no-swiping使用)
            },
        };
    },
    mounted() {
        this.$nextTick(() => {
            const swiperTop = this.$refs.swiperTop.$swiper;
            const swiperThumbs = this.$refs.swiperThumbs.$swiper;
            swiperTop.controller.control = swiperThumbs;
            swiperThumbs.controller.control = swiperTop;
        });
    },
    methods: {
        changeNum(params) {
            if (params == "next") {
                this.actIndex++
                if (this.actIndex > 7) {
                    this.actIndex = 1
                }
            }
            if (params == "prev") {
                this.actIndex--
                if (this.actIndex < 1) {
                    this.actIndex = 7
                }
            }
        }
    }
};
</script>

//样式，自行更改即可

<style lang="scss" scoped>
.thumb-example {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.swiper {
    margin-bottom: 10px;

    .swiper-slide {
        // background-size: cover;
        background-position: center;

        &.slide-1 {
            background-image: url('http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/情指一体化.png'); //图片地址
        }

        &.slide-2 {
            background-image: url('http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/卡口布控.png'); //图片地址
        }

        &.slide-3 {
            background-image: url('http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/图码联侦.png'); //图片地址
        }

        &.slide-4 {
            background-image: url('http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/智慧警务.png'); //图片地址
        }

        &.slide-5 {
            background-image: url('http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/地图.png'); //图片地址
        }

        &.slide-6 {
            background-image: url('http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/手机电脑.png'); //图片地址
        }

        &.slide-7 {
            background-image: url('http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/发散图.png'); //图片地址
        }
    }

    &.gallery-top {
        align-self: flex-start;
        height: 70%;
        width: 70%;
        margin: 0;
        background-color: #ccc;

        .swiper-slide {
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    &.gallery-thumbs {
        height: 30%;
        width: 100%;
        box-sizing: border-box;
        padding: gap 0;

        .swiper-wrapper {
            width: 100%;
            height: 100%;
        }
    }

    &.gallery-thumbs .swiper-slide {
        //等比例缩小
        opacity: 0.3;
        height: 100%;
        width: 68px;
        border: 1px solid #eee;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &.gallery-thumbs .swiper-slide-active {
        opacity: 1;
    }
}

.itemInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    height: 69%;
    background-color: #FA5603;
    padding: 20px;
    box-sizing: border-box;

    h3 {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 30px
    }

    p {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 38px;
        text-align: left;
    }
}
</style>
