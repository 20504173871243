import { render, staticRenderFns } from "./swiperAI.vue?vue&type=template&id=5e32a518&scoped=true&"
import script from "./swiperAI.vue?vue&type=script&lang=js&"
export * from "./swiperAI.vue?vue&type=script&lang=js&"
import style0 from "./swiperAI.vue?vue&type=style&index=0&id=5e32a518&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e32a518",
  null
  
)

export default component.exports