<template>
<div>
    <Nav />

    <!-- 上方海报 -->
    <div class="poster">
        <div class="introduce"></div>
    </div>
    <!-- 表单 -->
    <div class="main">
        <div class="main_L">
            <div :class="{ first: true, bgcolor: bg1Show }" @click="switchShow(1)">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/合作.png" alt="" />
                <p>商务合作</p>
            </div>
            <div :class="{ second: true, bgcolor: bg2Show }" @click="switchShow(2)">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/招聘.png" alt="" />
                <p>社会招聘</p>
            </div>
        </div>
        <div class="main_R">
            <el-form v-if="isShow" label-position="right" :rules="rules" ref="form" :model="form" label-width="100px">
                <el-form-item label="所属行业" prop="industry">
                    <el-input v-model="form.industry" placeholder="请选择行业"></el-input>
                </el-form-item>
                <el-form-item label="公司名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入正确公司名称"></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="contacts">
                    <el-input v-model="form.contacts" placeholder="请输入联系人"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="phone">
                    <el-input v-model="form.phone" placeholder="请输入联系电话"></el-input>
                </el-form-item>
                <el-form-item label="详情需求" prop="desc">
                    <el-input type="textarea" v-model="form.desc" placeholder="请输入300字以内详细需求" :rows="10"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                </el-form-item>
            </el-form>
            <div v-if="!isShow" class="position">
                <div class="poItem">
                    <div class="icon">
                        <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/产品经理图标.png" alt="" />
                        <h2>产品经理</h2>
                    </div>
                    <p>
                        熟练应用各种市场分析工具及产品原型工具能够撰写和输出规范的产品白皮书;有产品策划和产品管理思维,能够撰写市场调研、市场分析、可行性分析报告等;具备良好的沟通协调能力、系统性思维和创新意识;具有产品需求、归纳能力、市场敏觉洞察能力;经历过完整的企业级应用产品生命周期;有软件开发企业5年以上产品经理工作经验;有前端相关技术背景,具有产品架构师能力者优先考虑
                    </p>
                </div>
                <div class="poItem">
                    <div class="icon">
                        <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/IOS.png" alt="" />
                        <h2>IOS开发工程师</h2>
                    </div>
                    <p>
                        能完成高质量代码开发，配合QA解决代码缺陷，维护后续线上遇到的问题，有主导或者独立开发的上线作品，掌握产品从开发到上线的完整过程；有扎实的Objective-C/C/C++语言基础，数据结构和算法基础，多线程开发基础；熟练掌握基本的设计模式和设计原则，理解OBJ-C的Runtime的运行机制和iOS平台的内存管理机制；熟悉iOS开发工具和相关开发测试工具的使用，学习能力强，能够快速适应新的技术领域、学习新的技术；逻辑思维严密 ，能够快速分析和解决问题
                    </p>
                </div>
                <div class="poItem">
                    <div class="icon">
                        <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/前端图标.png" alt="" />
                        <h2>前端开发工程师</h2>
                    </div>
                    <p>
                        熟悉W3C标准和各主流浏览器在前端开发中的差异，能熟练运用DIV+CSS，提供针对不同浏览器的前端页面解决方案。移动HTML5的性能和其他优化，为用户呈现最好的界面交互体验和最好的性能。负责相关产品的需求以及前端程序的实现，提供合理的前端架构。改进和优化开发工具、开发流程、和开发框架。与产品、后台开发人员保持良好沟通，能快速理解、消化各方需求，并落实为具体的开发工作；能独立完成功能页面的设计与代码编写，配合产品团队完成功能页面的需求调研和分析。
                    </p>
                </div>
            </div>
        </div>
    </div>

    <!-- <Side /> -->

    <Footer />
</div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Nav from "@/components/nav.vue";
import Side from "@/components/side.vue";
export default {
    components: {
        Footer,
        Nav,
        Side,
    },
    data() {
        return {
            isShow: true,
            bg1Show: true,
            bg2Show: false,
            form: {
                industry: "",
                name: "",
                contacts: "",
                phone: "",
                desc: "",
            },
            rules: {
                industry: [{
                        required: true,
                        message: "请输入所属行业",
                        trigger: "blur",
                    },
                ],
                name: [{
                        required: true,
                        message: "请输入公司名称",
                        trigger: "blur",
                    },
                ],
                contacts: [{
                        required: true,
                        message: "请输入联系人",
                        trigger: "blur",
                    },
                ],
                phone: [{
                        required: true,
                        message: "请输入联系电话",
                        trigger: "blur",
                    },
                ],
                desc: [{
                        required: true,
                        message: "请输入详情需求",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    methods: {
        switchShow(num) {
            if (num == 1) {
                this.isShow = true;
                this.bg1Show = true;
                this.bg2Show = false;
            } else {
                this.isShow = false;
                this.bg1Show = false;
                this.bg2Show = true;
            }
        },
        submitForm() {
            console.log("1111");
        },
    },
};
</script>

<style lang="scss" scoped>
//   上方海报
.poster {
    width: 100%;
    height: 620px;
    background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/加入我们banner.png);
    background-repeat: no-repeat;
    background-size: 100% 110%;
    background-position: 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .introduce {
        width: 800px;
        text-align: left;
        margin-left: 180px;
        box-sizing: border-box;
        position: relative;

        .el-button {
            position: absolute;
            top: 85px;
            left: 390px;
            width: 150px;
            height: 58px;
            background: #fb5604;
            border-radius: 8px;
            border: none;
            font-size: 24px;
            font-weight: 500;
            color: #ffffff;
            line-height: 31px;
        }
    }
}

// 表单
.main {
    display: flex;
    justify-content: space-around;
    padding: 0 5%;
    height: 650px;
    box-sizing: border-box;

    .main_L {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 25%;

        .bgcolor {
            background: linear-gradient(0deg,
                    rgba(255, 255, 255, 0.3),
                    rgba(172, 203, 238, 1));
        }

        >div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 300px;
            height: 278px;
            border-radius: 42px;
            cursor: pointer;

            img {
                width: 110px;
                height: 102px;
            }

            p {
                font-size: 25px;
                font-weight: 400;
                color: #202020;
                line-height: 30px;
                margin-top: 20px;
            }
        }
    }

    .main_R {
        width: 65%;

        .el-form {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: 50%;
            box-sizing: border-box;
            margin-top: 80px;
            box-sizing: border-box;

            .el-form-item {
                width: 50%;
                margin-bottom: 40px;

                ::v-deep label {
                    font-size: 16px;
                }

                ::v-deep .el-input__inner {
                    height: 50px;
                }
            }

            .el-form-item:nth-child(5) {
                width: 100%;
            }

            .el-form-item:last-child {
                width: auto;
                .el-button {
                    width: 120px;
                }
            }
        }

        .position {
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 100%;

            @keyframes example {
                from {
                    height: 400px;
                    opacity: 0.1;
                }

                to {
                    height: 550px;
                    opacity: 1;
                }
            }

            .poItem:hover {
                height: 550px;
                background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/提交简历图.png);
                animation-name: example;
                animation-duration: 1.5s;

                p {
                    display: block;
                }
            }

            .poItem {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 350px;
                height: 400px;
                box-shadow: 0px 8px 9px 1px rgba(153, 153, 153, 0.3);
                padding: 20px;
                box-sizing: border-box;

                .icon {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    padding: 20px 0;
                    box-sizing: border-box;

                    img {
                        width: 100px;
                        height: 100px;
                    }

                    h2 {
                        font-size: 25px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #202020;
                        line-height: 30px;
                        margin-top: 20px;
                    }
                }

                p {
                    display: none;
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #717171;
                    line-height: 31px;
                    text-align: left;
                }

                // .info {
                //     // display: none;
                //     background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/提交简历图.png);
                //     padding: 40px 20px;
                //     box-sizing: border-box;

                //     p {
                //         font-size: 16px;
                //         font-family: Source Han Sans CN;
                //         font-weight: 400;
                //         color: #717171;
                //         line-height: 31px;
                //     }
                // }
            }
        }
    }
}
</style>
