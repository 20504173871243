<template>
<div class="fireControl">
    <Nav />
    <!-- 上方海报 -->
    <div class="poster">
        <div class="introduce">
            <h2>智慧消防解决方案</h2>
            <p>通过对云计算、物联网、大数据、GIS等现代信息技术的综合利用，大力创新消防管理模式，开展智慧防控、作战、执法和管理，对执法工作的系统化管理以及对管理运营的精细化管理。</p>
            <el-button type="" @click="consult">立即咨询</el-button>
        </div>
    </div>

    <!-- 消防物联云平台特点 -->
    <div class="Characteristics ">
        <h2>消防物联云平台特点</h2>
        <p>平台具有发现警情早、告警流程简单、处理警情自动化、自动通知火警所及周边人员撤离等功能，实现小微场所火灾事故的早发早报警、早扑灭，全面解决小微场所消防监督难点。</p>
    </div>
    <div class="ChaBox">
        <div class="ChaBoxmini">
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/消防-速度.png" alt="">
            <h4>速度快</h4>
            <p>烟感报警检测，用电故障探测，急速推送，及时报警，及时确认</p>
        </div>
        <div class="ChaBoxmini">
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/消防-多途径.png" alt="">
            <h4>多途径</h4>
            <p>平台通过自动语言、短信、APP推送等多种方式及时将火灾告警信息通知各类用户和监护人员。</p>
        </div>
        <div class="ChaBoxmini">
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/消防-国形化.png" alt="">
            <h4>国形化</h4>
            <p>各国图文并茂的展示模式的帮助监督人员，进行查询、统计警情、场所、网关探测器的各类信息。</p>
        </div>
        <div class="ChaBoxmini">
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/消防-多集成.png" alt="">
            <h4>多集成</h4>
            <p>系统集成了无线物联网技术、移动互联网技术、具有技术先进、预警实时、稳定可靠等优势。</p>
        </div>
        <div class="ChaBoxmini">
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/消防-多接口.png" alt="">
            <h4>多接口</h4>
            <p>提供数据AP接口(维保接口、接处警接口、智慧消防接口、119报警中心接口综合平台接口)。</p>
        </div>
    </div>

    <!-- 平台多途径推送火情及时告警 -->
    <div class="Characteristics ">
        <h2>平台多途径推送火情及时告警</h2>
        <p>平台通过电话自动语音、短信、APP推送及时将火灾告警信息发送至小微业主和消防管理员，小微业主可以设置最多五个的通知联系人，火警发生时将同时发送短信、轮播语音通知给相关联系人。火警通知第一时间会在管理监控平合弹窗提醒，监控人员可以第一时间通过平台查找联系业主确认火情。</p>
    </div>
    <div class="img">
        <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/消防-推送火情.png" alt="">
    </div>

    <!-- 重点部署场所 -->
    <div class="Characteristics ">
        <h2>重点部署场所</h2>
    </div>
    <div class="Focus ">
        <div class="imgbox">
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/消防-九小.png" alt="">
            <p>九小场所</p>
        </div>
        <div class="imgbox">
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/消防-人员密集.png" alt="">
            <p>人员密集场所</p>
        </div>
        <div class="imgbox">
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/消防-易燃易爆.png" alt="">
            <p>易燃易爆场所</p>
        </div>
        <div class="imgbox">
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/消防-劳动密集.png" alt="">
            <p>劳动密集场所</p>
        </div>
    </div>


    <Footer ref="Footer" />
</div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Nav from "@/components/nav.vue";
import Side from "@/components/side.vue";
export default {
    components: {
        Footer,
        Nav,
        Side
    },
    methods: {
        consult() {
            this.$refs.Footer.checkShow(2)
        }
    }
};
</script>

<style lang="scss" scoped>
.fireControl {
    // background-color: #002A79;
}

//   上方海报
.poster {
    width: 100%;
    height: 620px;
    background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/消防-banner.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .introduce {
        width: 800px;
        text-align: left;
        margin-left: 160px;
        box-sizing: border-box;

        h2 {
            font-size: 60px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #F4FBFB;
            line-height: 69px;
        }

        p {
            box-sizing: border-box;
            font-size: 22px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #F7FBFA;
            line-height: 33px;
            margin: 40px 0;
        }

        .el-button {
            margin-top: 100px;
            width: 224px;
            height: 58px;
            background: transparent;
            border: 2px solid #FFFFFF;
            box-shadow: 2px 2px 12px 6px rgba(2, 114, 230, 0.23);
            border-radius: 30px;
            // border: none;
            font-size: 24px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 31px;
        }
    }
}

// 消防物联云平台特点
.Characteristics{
    width: 1200px;
    margin: auto;
    padding: 50px 100px;
    h2{
        font-size: 24px;
        line-height: 50px;
        font-weight: 600;
        margin-bottom: 20px;
    }
    p{
        font-size: 16px;
        line-height: 24px;
        text-align-last: left;
        text-indent: 32px;
    }
    
}
.ChaBox{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 50px;
    .ChaBoxmini{
        width: 15%;
        padding: 50px 20px;
        border: 1px solid #e1e1e1;
        img{
            width: 50px;
        }
        h4{
            font-size: 20px;
            font-weight: 600;
            line-height: 100px;
        }
        p{
            font-size: 14px;
            line-height: 30px;
            text-align: left;
            padding: 0 10px;
        }
    }
}

.img{
    width: 80%;
    margin: auto;
    img{
        width: 100%;
    }
}
.Focus{
    width: 80%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .imgbox{
        width: 48%;
        margin-bottom: 20px;
        position: relative;
        img{
            width: 100%;
        }
        p{
            height: 50px;
            width: 100%;
            position: absolute;
            bottom: 0;
            text-align: center;
            line-height: 50px;
            background-color: rgba(0, 0, 0, .5);
            color: #fff;
        }
    }
}
</style>
