<template>
<div class="smartPolice">
    <Nav />
    <!-- 上方海报 -->
    <div class="poster">
        <div class="introduce">
            <h2>智慧公安</h2>
            <p>智慧公安建设依托公安云计算中心、公安应用平台和数据库，结合云计算、物联网、卫星定位、视频监控等多种先进技术，以移动信息化为辅助作为创新警务模式、提升执法效能、已达到多警种联动和提升队伍管理水平的强大推动力，帮助公安部门在应对各种复杂局面时作出更智慧的决策，提升公安机关工作的层次、质量和水平。</p>
            <el-button type="" @click="consult">立即咨询</el-button>
        </div>
    </div>
    <!-- 产品介绍 -->
    <!-- <div class="ProductIntroduction">
        <h2>什么是智慧城市？</h2>
        <p>
            智慧城市利用信息通信技术 (ICT)
            提高运营效率，与公众共享信息，并提供更优质的政府服务和公民 福利。
            智慧城市的主要目标是优化城市功能，促进经济增长，同时通过智能技术和数据分析提高市民的生活质量。
        </p>
    </div> -->
    <!-- 产品作用 -->
    <div class="ProductFunction1">
        <div class="funItem">
            <h2>数据共享与交换</h2>
            <p>
                内部数据壁垒仍未打通，“信息孤岛”现象仍然存在，无法有效共享，形成极大浪费。外部数据资源汇聚融合不充分、可实施性较差等问题，由此导致了外部数据资源接入混乱、管理成本和处理技术难度增加等问题，数据应用成效无法彰显。
            </p>
        </div>
        <div class="funItem">
            <h2>基础建设滞后于实战需要</h2>
            <p>
                国内现阶段GIS应用水平较低，在日常警务、犯罪分析与预防、智慧等实战中作用有限，随着云计算、互联网+、物联网、大数据等技术的步伐，有些基础建设不能满足实战需要。
            </p>
        </div>
        <div class="funItem">
            <h2>资源未实现统一调度</h2>
            <p>
                系统所采用的不同厂商的技术方案和技术标准互通兼容性不好，在管理上没有实现真正统一，无法做到资源统一调度，达不到集约管理、高效运用的目标。
            </p>
        </div>
    </div>
    <div class="main">
        <!-- 核心功能 -->
        <div class="coreFunctions">
            <h2 class="title">核心功能</h2>
            <!-- <div>
                <div class="core_L">
                    <img v-if="activeArea == 0" src="../../assets/image/情指一体化.png" alt="" />
                    <img v-if="activeArea == 1" src="../../assets/image/卡口布控.png" alt="" />
                    <img v-if="activeArea == 2" src="../../assets/image/图码联侦.png" alt="" />
                    <img v-if="activeArea == 3" src="../../assets/image/智慧警务.png" alt="" />
                </div>
                <div class="core_R">
                    <h3>情指一体化</h3>
                    <p>
                        基于NPGIS地理引擎及公安局的实际应用需求，结合大数据应用，在110接处警系统，视频监控系统、卡口大数据系统、无人机系统、智能识别系统等业务系统的基础上建设的一体化管控平台。
                    </p>
                </div>
            </div> -->
            <div class="swiper">
                <SwiperPollice />
                <!-- <div class="itemInfo">
                    <h3>情指一体化</h3>
                    <p>
                        基于NPGIS地理引擎及公安局的实际应用需求，结合大数据应用，在110接处警系统，视频监控系统、卡口大数据系统、无人机系统、智能识别系统等业务系统的基础上建设的一体化管控平台。
                    </p>
                </div> -->
            </div>
        </div>
        <!-- 平台优势  -->
        <div class="PlatformAdvantages">
            <h2 class="title">平台优势</h2>
            <div class="ProductFunction">
                <div class="funItem">
                    <h3>科学智能决策</h3>
                    <p>
                        通过大数据分析、AI算法为智慧公安提供科学预测、控制、分析决策，实现警务工作全生命周期管控。
                    </p>
                </div>
                <div class="funItem">
                    <h3>全周期智能化服务体系</h3>
                    <p>
                        提供全生命周期的服务体系，为公安领域量身定制从分析、规划、设计、研发、售后等特色服务及解决方案。
                    </p>
                </div>
                <div class="funItem">
                    <h3>高新技术应用能力</h3>
                    <p>
                        采用大数据、物联网、人工智能、区块链等核心技术为智慧公安赋能。
                    </p>
                </div>
            </div>
        </div>
        <!-- 客户案例 -->
        <div class="customerCase">
            <h2 class="title">客户案例</h2>
            <a href="../integrated">
                <div class="caseMain">
                    <p>情指一体化</p>
                </div>
            </a>
        </div>
    </div>

    <!-- <Side /> -->

    <Footer ref="Footer" />
</div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Nav from "@/components/nav.vue";
import ProTop from "../../components/productService/proTop.vue";
import Side from "@/components/side.vue";
import SwiperPollice from "@/components/swiperPolice.vue";
export default {
    components: {
        Footer,
        Nav,
        ProTop,
        Side,
        SwiperPollice
    },
    data() {
        return {
            activeArea: 0,
        };
    },
    methods: {
        areaSub() {
            if (this.activeArea <= 0) {
                this.activeArea = 3;
            } else {
                this.activeArea--;
            }
        },
        areaAdd() {
            if (this.activeArea >= 3) {
                this.activeArea = 0;
            } else {
                this.activeArea++;
            }
        },
        consult() {
            this.$refs.Footer.checkShow(2)
        }
    },
};
</script>

<style lang="scss" scoped>
// 产品介绍
// .ProductIntroduction {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     margin: 80px 0 60px 0;

//     h2 {
//         font-size: 30px;
//         font-weight: 500;
//         color: #202020;
//         margin-bottom: 20px;
//     }

//     p {
//         width: 890px;
//         text-align: center;
//         font-size: 18px;
//         font-weight: 400;
//         color: #202020;
//         line-height: 30px;
//     }
// }

// 产品作用
.ProductFunction1 {
    width: 80%;
    display: flex;
    justify-content: space-between;

    .funItem {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 480px;
        height: 280px;
        border: 1px solid #dadada;
        box-sizing: border-box;
        padding: 20px 35px;

        h2 {
            font-size: 24px;
            font-weight: 500;
            color: #202020;
            line-height: 48px;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            color: #717171;
            line-height: 31px;
            text-align: left;
        }
    }
}

//   上方海报
.poster {
    width: 100%;
    height: 620px;
    background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/home_swiper3.png);
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 80px;
    box-sizing: border-box;

    .introduce {
        width: 800px;
        text-align: left;
        margin-left: 180px;
        box-sizing: border-box;

        h2 {
            font-size: 60px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #fff;
            line-height: 69px;
        }

        p {
            box-sizing: border-box;
            font-size: 22px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #fff;
            line-height: 33px;
            margin: 40px 0;
        }

        .el-button {
            width: 224px;
            height: 58px;
            background: #FB5604;
            border-radius: 8px;
            border: none;
            font-size: 24px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 31px;
        }
    }
}

.smartPolice {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main {
        width: 80%;

        // 核心功能
        .coreFunctions {
            >div:nth-child(2) {
                display: flex;
                height: 550px;
                margin-bottom: 20px;
            }

            .swiper {
                position: relative;

                .itemInfo {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 30%;
                    height: 69%;
                    background-color: #FA5603;
                    padding: 20px;
                    box-sizing: border-box;

                    h3 {
                        font-size: 30px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 30px
                    }

                    p {
                        font-size: 18px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 38px;
                        text-align: left;
                    }
                }
            }
        }

        // 平台优势
        .PlatformAdvantages {
            .ProductFunction {
                display: flex;
                justify-content: space-between;

                .funItem {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    width: 480px;
                    height: 280px;
                    border: 1px solid #dadada;
                    box-sizing: border-box;
                    padding: 20px 35px;

                    h3 {
                        font-size: 24px;
                        font-weight: 500;
                        color: #202020;
                        line-height: 48px;
                    }

                    p {
                        font-size: 16px;
                        font-weight: 400;
                        color: #717171;
                        line-height: 31px;
                        text-align: left;
                    }
                }
            }
        }

        // 客户案例
        .customerCase {
            height: 350px;
            margin-bottom: 80px;

            .caseMain {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                height: 300px;
                background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/integrated.png);
                background-repeat: no-repeat;
                background-size: 100% 100%;

                p {
                    height: 60px;
                    font-size: 30px;
                    font-weight: 500;
                    color: #ffffff;
                    background-color: rgba($color: #000000, $alpha: 0.5);
                    line-height: 60px;
                }
            }
        }
    }
}
</style>
