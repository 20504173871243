<template>
<div>
    <div>
        <Nav />

        <!-- 上方海报 -->
        <div class="poster">
            <div class="introduce">
                <h2>大数据解决方案</h2>
                <p>
                    基于大数据技术从各类数据中快速获得有价值的信息并进行呈现、共享等服务
                    能够帮助企业聚合内外部数据,支撑高效的数据服务，最终提升企业决策水平。
                </p>
                <el-button type="" @click="consult">立即咨询</el-button>
            </div>
        </div>

        <!-- 产品与服务 -->
        <div class="service">
            <h2 class="ENtitle">CORE TECHNOLOGY</h2>
            <h2 class="CNtitle">产品与服务</h2>
            <div class="serveMain">
                <div>
                    <h3>数据中台</h3>
                    <p>把原始数据转化为数据资产，快速构建数据服务，使企业可以持续、充分地利用数据，实现数据可见、可用、可运营的目标，以数据来驱动决策和运营，不断深化数字化转型。
                    </p>
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/矢量智能对象.png" alt="">
                </div>
                <div>
                    <h3>大数据分析平台</h3>
                    <p>集成企业内部运营支撑系统和外部数据，包括交易型大数据(Big Transaction Data)和交互型大数据(Big Interaction Data)通过多种云计算的技术将之集成和处理，向企业内部和外部企业客户提供有极大商业价值的信息支撑和智能解决方案。</p>
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/矢量智能对象2.png" alt="">
                </div>
            </div>
        </div>

        <!-- 智慧城市大数据应用技术架构 -->
        <div class="architecture">
            <h2 class="ENtitle">SMART CITY BIG DATA APPLICATION TECHNOLOGY ARCHITECTURE</h2>
            <h2 class="CNtitle">智慧城市大数据应用技术架构</h2>
            <div class="arcMain">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/智慧城市大数据应用技术架构.png" alt="">
            </div>
        </div>

        <!-- 应用领域 -->
        <div class="area">
            <h2 class="ENtitle">APPLICATION AREA</h2>
            <h2 class="CNtitle">应用领域</h2>
            <div class="areaMain">
                <div>
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/data-金融.png" alt="">
                    <p>金融领域</p>
                </div>
                <div>
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/data-物流.png" alt="">
                    <p>物联网和物流领域</p>
                </div>
                <div>
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/data-公共.png" alt="">
                    <p>公共服务领域</p>
                </div>
                <div>
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/data-医疗.png" alt="">
                    <p>医疗领域</p>
                </div>
                <div>
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/data-能源.png" alt="">
                    <p>能源领域</p>
                </div>
                <div>
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/data-政务.png" alt="">
                    <p>政务领域</p>
                </div>
                <div>
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/data-司法.png" alt="">
                    <p>司法领域</p>
                </div>
                <div>
                    <el-button round>了解更多 ＞</el-button>
                </div>
            </div>
        </div>

        <!-- 方案优势 -->
        <div class="scheme">
            <h2 class="ENtitle">SCHEME ADVANTAGES</h2>
            <h2 class="CNtitle">方案优势</h2>
            <div class="schemeMain">
                <div>
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/data-场景.png" alt="">
                    <div class="itemText">
                        <h3>应用场景丰富</h3>
                        <p>可应用于金融、物联网、物流、政府、司法、数字经济、保险等多个领域，实现数据生命周期管理、数据共享等服务。</p>
                    </div>
                </div>
                <div>
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/data-体系.png" alt="">
                    <div class="itemText">
                        <h3>全面的服务体系</h3>
                        <p>提供全生命周期的服务体系，为客户量身定制从分析、规划、设计、研发、售后等特色服务及解决方案。
                        </p>
                    </div>
                </div>
                <div>
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/data-方案.png" alt="">
                    <div class="itemText">
                        <h3>成熟的项目落地方案</h3>
                        <p>已为上百家客户提供专业解决方案，遵循全球公认的ISO质量体系，精细打磨每一个细节。
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- 客户案列 -->
        <div class="case">
            <h2 class="ENtitle">APPLICATION AREA</h2>
            <h2 class="CNtitle">客户案列</h2>
            <div class="caseMain">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/大数据-客户案例-数据中台.png" alt="">
                <div class="shujuzhongtai" @click="$router.push('/dataCenter')">
                    数据中台
                </div>
            </div>
        </div>
    </div>

    <!-- <Side /> -->

    <Footer ref="Footer" />
</div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Nav from "@/components/nav.vue";
import Side from "@/components/side.vue";
export default {
    components: {
        Footer,
        Nav,
        Side
    },
    methods: {
        consult() {
            this.$refs.Footer.checkShow(2)
        }
    }
};
</script>

<style lang="scss" scoped>
//   上方海报
.poster {
    width: 100%;
    height: 620px;
    background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/大数据-banner.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .introduce {
        width: 800px;
        text-align: left;
        margin-left: 150px;
        box-sizing: border-box;

        h2 {
            font-size: 60px;
            font-weight: 400;
            color: #fff;
            line-height: 69px;
        }

        p {
            box-sizing: border-box;
            font-size: 22px;
            font-weight: 400;
            color: #fff;
            line-height: 33px;
            margin: 40px 0;
        }
        .el-button {
            width: 224px;
            height: 58px;
            border-radius: 8px;
            border: none;
            font-size: 24px;
            font-weight: 500;
            color: #0C0138;
            line-height: 31px;
            background: linear-gradient(0deg, #b9b9b9, rgba(255, 255, 255));
            box-shadow: 4px 4px 2px 0px rgba(0, 20, 66, 0.29);
        }
    }
}

// 产品与服务
.service {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;

    .serveMain {
        display: flex;
        justify-content: space-around;
        width: 90%;

        >div:hover {

            h3,
            p {
                color: #ffffff;
            }
        }

        >div {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            position: relative;
            width: 666px;
            height: 250px;
            text-align: left;
            padding: 0 130px 40px 40px;
            box-sizing: border-box;
            box-shadow: 0px 14px 21px 0px #E3E6F0;

            h3 {
                font-size: 24px;
                font-weight: 500;
                color: #202020;
                line-height: 48px;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                color: #717171;
                line-height: 31px;
            }

            img {
                position: absolute;
                top: 0;
                right: -90px;
                bottom: 0;
                margin: auto;
                width: 180px;
                height: 200px;
            }
        }

        >div:hover {
            background: linear-gradient(0deg, #A1C4FD, #C2E9FB);
        }
    }
}

// 智慧城市大数据应用技术架构
.architecture {
    padding-top: 80px;
    width: 90%;
    margin: auto;
    img {
        width: 100%;
        height: 100%;
    }
}

// 应用领域
.area {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;

    .areaMain {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 90%;

        >div {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 366px;
            height: 175px;
            box-shadow: 0px 4px 6px 0px rgba(204, 204, 204, 0.92);
            margin-bottom: 45px;
            box-sizing: border-box;

            img {
                width: 125px;
                height: 108px;
            }

            p {
                font-size: 24px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #202020;
                line-height: 30px;
            }
        }

        .el-button {
            width: 225px;
            height: 60px;
            border-radius: 30px;
            font-size: 24px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 30px;
            background: linear-gradient(-36deg, #F7CB6B 0%, #FBA980 62%);
        }

        .more {}
    }
}

// 方案优势
.scheme {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;

    .schemeMain {
        display: flex;
        justify-content: space-between;
        width: 90%;

        >div {
            width: 500px;
            height: 525px;
            background: #FFFFFF;
            box-shadow: 0px 4px 6px 0px rgba(204, 204, 204, 0.92);
            padding-bottom: 60px;
            box-sizing: border-box;

            img {
                width: 500px;
                height: 263px;
            }

            .itemText {
                padding: 0 70px;

                h3 {
                    font-size: 24px;
                    font-weight: 400;
                    color: #202020;
                    line-height: 30px;
                    margin: 40px 0;
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    color: #717171;
                    line-height: 30px;
                }
            }
        }
    }
}

// 客户案列
.case {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px 0;

    .caseMain {
        width: 90%;
        position: relative;
        img {
            width: 100%;
        }
        .shujuzhongtai{
            width: 100%;
            height: 60px;
            line-height: 60px;
            position: absolute;
            bottom: 0;
            font-size: 24px;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.3000);
            cursor:pointer;
        }
    }
}
</style>
