<template>
<div class="blockchain">
    <Nav />

    <!-- 上方海报 -->
    <div class="poster">
        <div class="introduce">
            <h2>区块链解决方案</h2>
            <p>
                区块链是一种新型去中心化协议，能安全地存储比特币交易或其它数据，信息不可伪造和篡改，可以自动执行暂能合约，无需任何中心化机构的审核。可基于区块链实现数据可信传递，数据可信验证等能力，协助企业、政府、司法等行业构建安全、可信的数字协同。
            </p>
            <el-button type="" @click="consult">立即咨询</el-button>
        </div>
    </div>

    <!-- 核心技术 -->
    <div class="coreTechnology">
        <h2 class="ENtitle">CORE TECHNOLOGY</h2>
        <h2 class="CNtitle">核心技术</h2>
        <div class="logymain">
            <div class="logyItem">
                <div class="itemTop">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/记账本.png" alt="">
                    <h3>分布式账本</h3>
                </div>
                <p>交易记账由分布在不同地方的多个节点共同完成，而且每一个节点记录的是完整的账目，因此它们都可以参与监督交易合法性，同时也可以共同为其作证

                </p>
                <!-- <el-button round>了解详情</el-button> -->
            </div>
            <div class="logyItem">
                <div class="itemTop">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/对称.png" alt="">
                    <h3>非对称加密</h3>
                </div>
                <p>存储在区块链上的交易信息是公开的，但是账户身份信息是高度加密的，只有在数据拥有者授权的情况下才能访问到，从而保证了数据的安全和个人的隐私
                </p>
                <!-- <el-button round>了解详情</el-button> -->
            </div>
            <div class="logyItem">
                <div class="itemTop">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/机制.png" alt="">
                    <h3>共识机制</h3>
                </div>
                <p>共识机制就是所有记账节点之间怎么达成共识，去认定一个记录的有效性，这既是认定的手段，也是防止篡改的手段。区块链提出了四种不同的共识机制，适用于不同的应用场景，在效率和安全性之间取得平衡

                </p>
                <!-- <el-button round>了解详情</el-button> -->
            </div>
            <div class="logyItem">
                <div class="itemTop">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/合约.png" alt="">
                    <h3>智能合约</h3>
                </div>
                <p>智能合约是基于这些可信的不可篡改的数据可以自动化的执行一些预先定义好的规则和条款
                </p>
                <!-- <el-button round>了解详情</el-button> -->
            </div>
        </div>
    </div>

    <div class="main">
        <!-- 产品于服务 -->
        <div class="product">
            <h2 class="ENtitle">PRODUCT IN SERVER</h2>
            <h2 class="CNtitle">产品与服务</h2>
            <div class="proMain">
                <div class="proItem">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/身份认证.png" alt="">
                    <h3>基于区块链技术的身份认证</h3>
                    <p>提供统一的分布式身份标识，支持多场景细粒度的可验证凭证管理能力
                    </p>
                    <!-- <img class="btn" src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/按钮.png" alt=""> -->
                </div>
                <div class="proItem">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/查询.png" alt="">
                    <h3>可信信息查询平台</h3>
                    <p>基于上链的数字资产及资产之间的关联关系，整合及特征抽取和使用构图技术，帮助用户快速查询实体、资产、业务等信息相关全面、可靠的数据信息
                    </p>
                    <!-- <img class="btn" src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/按钮.png" alt=""> -->
                </div>
                <div class="proItem">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/管理.png" alt="">
                    <h3>可信信息管理平台</h3>
                    <p>基于区块链构建的数字资产链平台，基于数据资产管理、数据时上链，状态机、智能合约实现数字资产的确权、可信保存、安全交易
                    </p>
                    <!-- <img class="btn" src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/按钮.png" alt=""> -->
                </div>
                <div class="proItem">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/网络.png" alt="">
                    <h3>区块链网络平台</h3>
                    <p>区块链网络平台事为资产通证提供账本及智能合约服务的技术基础设施，在宏观上，区块链网络平台由数据层、网络层、共识层、合约层、接口层组成
                    </p>
                    <!-- <img class="btn" src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/按钮.png" alt=""> -->
                </div>
                <div class="proItem">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/存储.png" alt="">
                    <h3>加密存储平台</h3>
                    <p>通过严格的授权访问机制，保证数据可控访问。针对结构化数据存储，提供安全、高持久分布式存储功能，支持结构化数据高性能存储功能
                    </p>
                    <!-- <img class="btn" src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/按钮.png" alt=""> -->
                </div>
            </div>
        </div>

        <!-- 应用领地 -->
        <div class="territory">
            <h2 class="ENtitle">APPLIED TERRITORY</h2>
            <h2 class="CNtitle">应用领地</h2>
            <div class="terrMain">
                <div class="terrItem">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/金融业.png" alt="">
                    <div>
                        <h3>金融</h3>
                        <p>将区块链技术应用在金融行业中，能够省去第三方中介环节，实现点对点的直接对接，从而在大大降低成本的同时，快速完成交易支付。</p>
                    </div>
                </div>
                <div class="terrItem">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/国际物流.png" alt="">
                    <div>
                        <h3>物联网和物流领域</h3>
                        <p>通过区块链可以降低物流成本，追溯物品的生产和运送过程，并且提高供应链管理的效率。</p>
                    </div>
                </div>
                <div class="terrItem">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/服务管理.png" alt="">
                    <div>
                        <h3>数字版权领域</h3>
                        <p>通过区块链技术，可以对作品进行鉴权，证明文字、视频、音频等作品的存在，保证权属的真实、唯一性。作品在区块链上被确权后，后续交易都会进行实时记录,实现数字版权全生命周期管理，也可作为司法取证中的技术性保障。</p>
                    </div>
                </div>
                <div class="terrItem">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/公共服务领域.png" alt="">
                    <div>
                        <h3>公共服务领域</h3>
                        <p>可用于确保某个重要的基础设施的操作系统和固件没有被篡改可以监控软件的状被篡改，可以监控软件的状态和完整性，发现不良的篡改，并确保使用了物联网技术的系统所传输的数据没有经过篡改</p>
                    </div>
                </div>
                <div class="terrItem">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/保险.png" alt="">
                    <div>
                        <h3>保险领域
                        </h3>
                        <p>通过智能合约的应用，既无需投保人申请，也无需保险公司批准，只要触发理赔条件，实现保单自动理赔
                        </p>
                    </div>
                </div>
                <div class="terrItem">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/公益.png" alt="">
                    <div>
                        <h3>公益领域</h3>
                        <p>捐赠项目、募集明细、资金流向、受助人反馈等，均可以存放于区块链上，并且有条件地进行透明公开公示，方便社会监督。</p>
                    </div>
                </div>
                <div class="terrItem">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/司法.png" alt="">
                    <div>
                        <h3>司法领域</h3>
                        <p>最高人民法院于2022年5月25日发布区块链技术在提升司法公信力、提高司法效率、增强司法协同能力、服务经济社会治理等最高人民法院于2022年5月25日发布区块链技术在提升司法公信力、提高司法效率、增强司法协同能力、服务经济社会治理等</p>
                    </div>
                </div>
                <div class="terrItem">
                    <!-- <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/司法001.png" alt=""> -->
                    <p>......</p>
                    <div>
                        <el-button type="">了解更多</el-button>
                    </div>
                </div>
            </div>
        </div>

        <!-- 方案优势 -->
        <div class="advantage">
            <h2 class="ENtitle">ADVANTAGE</h2>
            <h2 class="CNtitle">方案优势</h2>
            <div class="advaMain">
                <div class="advaItem">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/应用场景.png" alt="">
                    <h3>应用场景丰富</h3>
                    <p>可应用于金融、物联网、物流、政府、司法、数字经济、保险等多个领域，实现数字可信、数据全生命周期管理、数据共享等可信服务
                    </p>
                </div>
                <div class="advaItem">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/去中心化.png" alt="">
                    <h3>去中心化</h3>
                    <p>区块链技术不依赖额外的第三方管理机构或硬件设施,没有中心管制，除了自成一体的区块链本身，通过分布式核算和存储，各个节点实现了信息自我验证、传递和管理
                    </p>
                </div>
                <div class="advaItem">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/开放性.png" alt="">
                    <h3>开放性</h3>
                    <p>区块链技术基础是开源的，除了交易各方的私有信息被加密外，区块链的数据对所有人开放，任何人都可以通过公开的接口查询区块链数据和开发相关应用，因此整个系统信息高度透明
                    </p>
                </div>
                <div class="advaItem">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/区块链背景.png" alt="">
                    <h3>安全可靠</h3>
                    <p>只要不能掌控全部数据节点的51%，就无法肆意操控修改网络数据，这使区块链本身变得相对安全，避免了主观人为的数据变更
                    </p>
                </div>
            </div>
        </div>

        <!-- 客户案列 -->
        <div class="case">
            <h2 class="ENtitle">CUSTOMER CASE</h2>
            <h2 class="CNtitle">客户案列</h2>
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/区块链-客户案例-可信服务.png" alt="">
            <h3 class="gototrustedService"><router-link to="/trustedService">可信服务>>></router-link></h3>
        </div>
    </div>

    <!-- <Side /> -->

    <Footer ref="Footer" />
</div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Nav from "@/components/nav.vue";
import Side from "@/components/side.vue";
export default {
    components: {
        Footer,
        Nav,
        Side
    },
    methods: {
        consult() {
            this.$refs.Footer.checkShow(2)
        }
    }
};
</script>

<style lang="scss" scoped>
//   上方海报
.poster {
    width: 100%;
    height: 620px;
    background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/区块链-banner.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .introduce {
        width: 800px;
        text-align: left;
        margin-left: 160px;
        box-sizing: border-box;

        h2 {
            font-size: 60px;
            font-weight: 400;
            color: #fff;
            line-height: 69px;
        }

        p {
            box-sizing: border-box;
            font-size: 22px;
            font-weight: 400;
            color: #fff;
            line-height: 33px;
            margin: 40px 0;
        }

        .el-button {
            width: 224px;
            height: 58px;
            background: #FB5604;
            border-radius: 8px;
            border: none;
            font-size: 24px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 31px;
        }
    }
}

.blockchain {
    background-color: #f7f4f8;
}

// 轮播
.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 575px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}

// 核心技术
.coreTechnology {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 75px;
    margin-bottom: 50px;
    box-sizing: border-box;

    .logymain {
        display: flex;
        justify-content: space-around;
        width: 90%;

        .logyItem:hover {
            box-shadow: 0px 8px 9px 1px rgba(153, 153, 153, 0.3000);
        }

        .logyItem {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            padding: 0 30px;
            box-sizing: border-box;
            width: 385px;
            height: 450px;
            background: #fff;

            .itemTop {
                p {
                    width: 124px;
                    height: 124px;
                    background: linear-gradient(148deg, #09BBFE 0%, #5A42EC 100%);
                    border-radius: 50%;
                    margin-bottom: 25px;
                    box-sizing: border-box;
                }

                img {
                    width: 100px;
                    height: 100px;
                }

                h3 {
                    font-size: 24px;
                    font-weight: 500;
                    color: #202020;
                    margin-top: 30px;
                    box-sizing: border-box;
                }
            }

            p {
                text-align: left;
                font-size: 16px;
                font-weight: 400;
                color: #717171;
                line-height: 31px;
            }

            .el-button {
                color: #FB5604;
                border: 1px solid #FB5604;
            }
        }
    }
}

// 服务与产品
.main {
    background-color: #fff;
    padding-top: 80px;

    .product {
        margin-bottom: 75px;
        box-sizing: border-box;

        .proMain {
            display: flex;
            justify-content: space-between;

            div:nth-child(1) {
                background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/50.png);
            }

            div:nth-child(2) {
                background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/51.png);
            }

            div:nth-child(3) {
                background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/52.png);
            }

            div:nth-child(4) {
                background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/53.png);
            }

            div:nth-child(5) {
                background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/54.png);
            }

            .proItem:hover {
                box-shadow: 0px 8px 9px 1px rgba(153, 153, 153, 0.8);
            }

            .proItem {
                // display: flex;
                // flex-direction: column;
                // justify-content: space-around;
                align-items: center;
                width: 370px;
                height: 520px;
                padding: 50px;
                box-sizing: border-box;
                background: #F8F6F9;
                background-repeat: no-repeat;
                background-size: 100% 100%;

                img {
                    align-self: flex-start;
                    width: 95px;
                    height: 83px;
                }

                h3 {
                    font-size: 22px;
                    font-weight: 700;
                    color: #000000;
                    line-height: 100px;
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 31px;
                    text-align: left;
                }

                .btn {
                    align-self: flex-end;
                }
            }
        }
    }

    // 应用领地
    .territory {
        // background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/区块链背景.png);
        padding: 30px 100px 50px 100px !important;
        box-sizing: border-box;

        h2 {
            color: #fff;
        }

        .terrMain {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            .terrItem:hover {
                background-color: #fff;
            }

            .terrItem {
                display: flex;
                width: 24%;
                // height: 225px;
                background-color: rgba($color: #fff, $alpha: 0.6);
                border-radius: 23px;
                padding: 40px 50px;
                box-sizing: border-box;
                margin-bottom: 25px;
                border: 1px solid #eee;
                img {
                    width: 50px;
                    height: 50px;
                    margin-right: 25px;
                }

                h3 {
                    text-align: left;
                    font-size: 24px;
                    font-weight: 500;
                    color: #333333;
                    line-height: 48px;
                }

                p {
                    text-align: left;
                    font-size: 16px;
                    font-weight: 400;
                    color: #000;
                    line-height: 31px;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .el-button {
                    width: 150px;
                    border: 1px solid #FB5604;
                    border-radius: 21px;
                    color: #FB5604;
                    margin-top: 50px;
                }
            }
        }
    }

    // 方案优势
    .advantage {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 80px;

        .advaMain {
            display: flex;
            justify-content: space-around;
            width: 90%;

            .advaItem {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 390px;
                height: 500px;
                background: #fff;
                border: 1px solid #CBC8CB;
                box-sizing: border-box;

                img {
                    width: 100%;
                    height: 200px;
                }

                h3 {
                    margin: 50px 0 40px 0;
                    font-size: 24px;
                    font-weight: 400;
                    color: #202020;
                    line-height: 30px;
                }

                p {
                    width: 90%;
                    font-size: 16px;
                    font-weight: 400;
                    color: #717171;
                    line-height: 30px;
                    text-align: left;
                }
            }
        }
    }

    // 客户案例
    .case {
        width: calc(100% - 160px);
        margin:40px auto;
        position: relative;
        img {
            width: 100%;
        }
        .gototrustedService{
            width: 100%;
            height: 60px;
            line-height: 60px;
            font-size: 24px;
            position: absolute;
            left: 50%;
            transform: translatex(-50%);
            bottom: 0;
            background-color: rgba(153, 153, 153, 0.3000);
            // border: 1px solid #fb5604;
            border-radius: 10px;
            a{
                color: #fff;
            }
        }
    }
}
</style>
