<template>
<div>
    <Nav />
    <!-- 上方海报 -->
    <!-- <div class="poster">
        <div class="introduce">
            <el-button type="" @click="consult">立即咨询</el-button>
        </div>
    </div> -->

    <div class="main">
        <!-- 项目背景 -->
        <div class="background mt80">
            <h2 class="ENtitle">PROJECT BACKGROUND</h2>
            <h2 class="CNtitle">项目背景</h2>
            <div class="bgMain">
                <div>
                    <!-- <img src="../assets/image/背景.png" alt="" /> -->
                </div>
                <div class="bgInfo">
                    <p>
                        为某市定制化开发人口、法人、空间地理三大基础库，通过单位原始数据汇聚，标准化治理后进行分类形成各个标准基础信息库，向用户提供标准数据目录、标准数据校验和查询服务，并针对基础信息库数据进行统计分析实现可视化的展现，为用户决策提供数据支撑。
                    </p>
                    <!-- <el-button>了解详情</el-button> -->
                </div>
            </div>
        </div>

        <!-- 项目痛点 -->
        <div class="background mt80">
            <h2 class="ENtitle">PROJECT PAIN POINT</h2>
            <h2 class="CNtitle">项目痛点</h2>
            <h3 class="info">
                我国宏观经济管理的发展趋势是逐步实现科学化、民主化、法制化。
            </h3>
            <div class="bgMain">
                <div class="bgInfo">
                    <p>
                        这是建立和完善社会主义市场经济体制的要求，也是提高宏观经济管理水平和效率、促进国民经济持续、稳定、健康发展的需要。这对宏观经济管理信息系统的建设提出了更高的要求。
                    </p>
                    <!-- <el-button>了解详情</el-button> -->
                </div>
                <div>
                    <!-- <img src="../assets/image/痛点.png" alt="" /> -->
                </div>
            </div>
        </div>

        <!-- 解决方案 -->
        <div class="solution mt80">
            <h2 class="ENtitle">SOLUTION</h2>
            <h2 class="CNtitle">解决方案</h2>
            <h3 class="info">
                以宏观经济数据为主体，通过大数据技术，以各级各类普查资料、政府综合统计资料、部门
                门行业登记资料、社会经济调查资料等为基础。对本地区经济、社会、人口、科技和环境资源等基础性信息进行有计划地收集、整合、存储和管理。
            </h3>
            <div class="soluMain">
                <div class="soluItem">
                    <div>
                        <!-- <img src="../assets/image/21.png" alt="" /> -->
                        <span>指标、维度维护</span>
                    </div>
                    <p>指标和维度是数据仓库的基础，可分层次维护。</p>
                </div>
                <div class="soluItem">
                    <div>
                        <!-- <img src="../assets/image/22.png" alt="" /> -->
                        <span>可定制数 据导人</span>
                    </div>
                    <p>针对不同源的历史数据可定制模板，导人数据到数据仓库中。</p>
                </div>
                <div class="soluItem">
                    <div>
                        <!-- <img src="../assets/image/23.png" alt="" /> -->
                        <span>可定制的 报表模板</span>
                    </div>
                    <p>
                        可定制报表表式、汇总公式，并可对计算结果修改，计算结果可保存成文件，同时将数据保存到数据仓库中。
                    </p>
                </div>
                <div class="soluItem">
                    <div>
                        <!-- <img src="../assets/image/24.png" alt="" /> -->
                        <span>可定制 的主题</span>
                    </div>
                    <p>指标和维度是数据仓库的基础，可分层次维护。</p>
                </div>
                <div class="soluItem">
                    <div>
                        <!-- <img src="../assets/image/25.png" alt="" /> -->
                        <span>多维查询 分析</span>
                    </div>
                    <p>
                        由于数据库采用数据仓库结构，所以可通过主题，或直接对汇总后的综合数据进行多维查询;通过选择指标和维度查询数据，并支持切片和旋转。
                    </p>
                </div>
            </div>
        </div>

        <!-- 项目成就 -->
        <div class="achievements">
            <h2 class="ENtitle">PROJECT ACHIEVEMENTS</h2>
            <h2 class="CNtitle">项目成就</h2>
            <div>
                <p>
                    建成后的系统将能够实现宏观经济管理部门之间的互联互通和信息共享，促进宏观经济管理部门间的业务协同与互动，大大提高宏观经济管理决策水平。
                </p>
            </div>
        </div>
    </div>

    <!-- <Side /> -->

    <Footer ref="Footer" />
</div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Nav from "@/components/nav.vue";
import Side from "@/components/side.vue";
export default {
    components: {
        Footer,
        Nav,
        Side
    },
    methods: {
        consult() {
            this.$refs.Footer.checkShow(2)
        }
    }
};
</script>

<style lang="scss" scoped>
//   上方海报
.poster {
    width: 100%;
    height: 620px;
    // background-image: url(../assets/image/经济库banner.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .introduce {
        width: 800px;
        text-align: left;
        margin-left: 160px;
        box-sizing: border-box;

        .el-button {
            margin-top: 350px;
            width: 224px;
            height: 58px;
            background: #fb5604;
            border-radius: 8px;
            border: none;
            font-size: 24px;
            font-weight: 500;
            color: #ffffff;
            line-height: 31px;
        }
    }
}

.main {
    box-sizing: border-box;
    padding: 0 5%;

    .info {
        padding: 0 15%;
        box-sizing: border-box;
        font-size: 19px;
        margin: 30px 0;
        font-weight: 400;
        color: #333333;
        // text-align: left;
    }

    // 项目背景
    .background {
        .bgMain {
            display: flex;

            img {
                width: 756px;
                height: 354px;
            }

            div:nth-child(1) {
                padding-right: 90px;
                box-sizing: border-box;
            }

            .bgInfo {
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                p {
                    text-align: left;
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #717171;
                    line-height: 31px;
                }

                .el-button {
                    width: 177px;
                    height: 52px;
                    border: 2px solid #fd5207;
                    border-radius: 10px;
                    color: #fd5207;
                }
            }
        }
    }

    // 解决方案
    .solution {

        .soluMain {
            display: flex;
            justify-content: space-around;

            .soluItem {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                width: 310px;
                height: 375px;
                padding: 0 15px;
                box-sizing: border-box;
                box-shadow: 3px 5px 9px 1px rgba(153, 153, 153, 0.2);

                div {
                    display: flex;

                    img {
                        width: 130px;
                        height: 100px;
                    }

                    span {
                        margin-left: 30px;
                        box-sizing: border-box;
                        text-align: left;
                        font-size: 24px;
                        font-weight: 400;
                        color: #202020;
                        line-height: 30px;
                    }
                }

                p {
                    text-align: left;
                    font-size: 16px;
                    font-weight: 400;
                    color: #717171;
                    line-height: 30px;
                }
            }

            .soluItem:hover {
                background: linear-gradient(0deg, #ACCBEE, #E7F0FD);
                box-shadow: 3px 5px 9px 1px rgba(153, 153, 153, 0.3);
            }
        }
    }

    // 项目成就
    .achievements {
        margin: 80px 0;
        padding-top: 80px;
        box-sizing: border-box;
        width: 100%;
        height: 525px;
        // background-image: url(../assets/image/成就.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        p {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            color: #000;
            line-height: 30px;
            width: 925px;
            height: 162px;
            background: linear-gradient(0deg, #ACCBEE 0%, #E7F0FD 100%);
            border-radius: 0px 80px 80px 0;
            padding: 0 50px;
            box-sizing: border-box;
        }
    }
}
</style>
