<template>
<div>
    <Nav />
    <!-- 上方海报 -->
    <div class="poster">
        <div class="introduce">
            <h2>社区解决方案</h2>
            <p>
                利用物联网、云计算、移动互联网等新一代信息技术的集成应用，为社区居民提供
                一个安全、舒适、便利的现代化、智慧化生活环境，从而形成基于信息化、智能化社会管理与服务的一种新的管理形态的社区。
            </p>
            <el-button type="" @click="consult">立即咨询</el-button>
        </div>
    </div>

    <!-- 方案优势 -->
    <div class="advantage">
        <h2 class="CNtitle">方案优势</h2>
        <div>
            <div>
                <h3>提高社区智能化水平</h3>
                <p>
                    基于信息化、物联网、人工智能等技术实现社区安防实时监控预警，门禁、车禁、安防等智能化管控。
                </p>
            </div>
            <div>
                <h3>态势感知大脑，辅助决策</h3>
                <p>
                    利用大数据可视化、物联网、人工智能等技术，实现社区服务的全面感知，提高企业管理能力，给居民生活创造便利。
                </p>
            </div>
            <div>
                <h3>为企业提供一站式解决方案</h3>
                <p>
                    为能源行业提供多场景解决方案，覆盖设备接入、设备监控、人口管理、出行管理、安防管理、门禁、车禁等功能交付及售后服务。
                </p>
            </div>
        </div>
    </div>

    <!-- 智慧社区特点 -->
    <div class="trait">
        <h2 class="CNtitle">智慧社区特点</h2>
        <div class="traitMain">
            <div class="MainSide">
                <div>
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/物联网2.png" alt="" />
                    <h3>物联网</h3>
                    <p>
                        基于信息化、物联网、人工智能等技术实现社区安防实时监控预警，门禁、车禁、安防等智能化管控。
                    </p>
                </div>
                <div>
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/物联网2.png" alt="" />
                    <h3>软件平台</h3>
                    <p>
                        基于信息化、物联网、人工智能等技术实现社区安防实时监控预警，门禁、车禁、安防等智能化管控。
                    </p>
                </div>
            </div>
            <div class="MainM">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/社区特点.png" alt="">
            </div>
            <div class="MainSide">
                <div>
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/物联网2.png" alt="" />
                    <h3>数据对接</h3>
                    <p>
                        有效的人员、车辆管控智能全息档案，动态管理房屋、人员、车辆的信息。
                    </p>
                </div>
                <div>
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/物联网2.png" alt="" />
                    <h3>数据分析</h3>
                    <p>
                        流动人员管控，采集社区流动人口数据；针对社区重点人员管控;基于大数据基础上提供预测预警机制。
                    </p>
                </div>
            </div>
        </div>
    </div>

    <!-- 智慧社区产品 -->
    <div class="product">
        <h2 class="CNtitle">智慧社区产品</h2>
        <div class="proMain">
            <div>
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/物业管理.png" alt="">
                <h3>物业管理平台</h3>
                <p>连接物业、居民、社区服务提供方，形成“互联网+物业”一站式解决方案，打造智慧物业健康新生态。</p>
            </div>
            <div>
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/智慧消防.png" alt="">
                <h3>智慧消防管理平台</h3>
                <p>基于SAAS模式的新一代“智慧消防大数据平台”可实时监控消防设备、实时告警、数据分析提前预警，对异常水、电、气、烟、火等实时监测并告警。</p>
            </div>
            <div>
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/智能门禁.png" alt="">
                <h3>智能门禁管理平台</h3>
                <p>基于智能物联网、AI、大数据的逻辑推理，让门禁更智能。支持人脸开门、密码开门、刷卡开门等多种开门方式。</p>
            </div>
            <div>
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/智慧社区.png" alt="">
                <h3>智慧社区警务管理平台</h3>
                <p>基于一标四实标准进行全方位建设的智慧社区警务管理平台。</p>
            </div>
            <div>
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/社区.png" alt="">
                <h3>社区党建平台</h3>
                <p>通过平台的建设，形成一个多方参与、共建共享的多元共治体系。</p>
            </div>
            <div>
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/车辆.png" alt="">
                <h3>车辆识别智能管控系统</h3>
                <p>基于AI技术，自动识别进出车辆，在线缴费，完成一体化服务。</p>
            </div>
        </div>
    </div>

    <!-- <Side /> -->

    <Footer ref="Footer" />
</div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Nav from "@/components/nav.vue";
import Side from "@/components/side.vue";
export default {
    components: {
        Footer,
        Nav,
        Side
    },
    methods: {
        consult() {
            this.$refs.Footer.checkShow(2)
        }
    }
};
</script>

<style lang="scss" scoped>
//   上方海报
.poster {
    width: 100%;
    height: 620px;
    background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/社区banner.png);
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .introduce {
        width: 800px;
        text-align: left;
        margin-left: 180px;
        box-sizing: border-box;

        h2 {
            font-size: 60px;
            font-weight: 400;
            color: #fff;
            line-height: 69px;
        }

        p {
            box-sizing: border-box;
            font-size: 22px;
            font-weight: 400;
            color: #fff;
            line-height: 33px;
            margin: 40px 0;
        }

        .el-button {
            width: 224px;
            height: 58px;
            background: #fb5604;
            border-radius: 8px;
            border: none;
            font-size: 24px;
            font-weight: 500;
            color: #ffffff;
            line-height: 31px;
        }
    }
}

// 方案优势
.advantage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;

    >div {
        display: flex;
        justify-content: space-between;
        width: 90%;

        >div {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 520px;
            height: 280px;
            border: 1px solid #dadada;
            padding: 20px 20px 50px 20px;
            box-sizing: border-box;

            h3 {
                font-size: 24px;
                font-weight: 500;
                color: #202020;
                line-height: 48px;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                color: #717171;
                line-height: 31px;
                text-align: left;
            }
        }
    }
}

// 智慧社区特点
.trait {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px 0;

    .traitMain {
        display: flex;
        justify-content: space-between;
        width: 90%;
    }

    .MainSide {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 400px;
        height: 500px;

        img {
            width: 72px;
            height: 72px;
        }

        h3 {
            font-size: 24px;
            font-weight: 500;
            color: #202020;
            line-height: 48px;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            color: #717171;
            line-height: 31px;
        }
    }

    .MainM {
        align-self: center;

        img {
            width: 368px;
            height: 345px;
        }
    }
}

// 智慧社区产品
.product {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;

    .proMain {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 90%;

        div {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 520px;
            height: 330px;
            border: 1px solid #DADADA;
            margin-bottom: 20px;
            border-bottom: 8px solid #FB5604;
            padding: 0 20px;
            box-sizing: border-box;

            img {
                align-self: center;
                width: 75px;
                height: 75px;
            }

            h3 {
                font-size: 24px;
                font-weight: 500;
                color: #202020;
                line-height: 48px;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                color: #717171;
                line-height: 31px;
                text-align: left;
            }
        }
    }
}
</style>
