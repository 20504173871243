<template>
<div>
    <Nav />

    <!-- 上方海报 -->
    <div class="poster">
        <div class="introduce">
            <h2>制造业解决方案</h2>
            <p>
                提供制造业全产业链解决方案，从销售、生产、库存、物流、OA等全面过管控实现制造业综合效率，减少生产损耗，提升企业经营能力。
            </p>
            <el-button type="" @click="consult">立即咨询</el-button>
        </div>
    </div>

    <!-- 方案优势 -->
    <div class="advantage">
        <h2 class="CNtitle">方案优势</h2>
        <div>
            <div>
                <h3>提高生产效率，降低运营成本</h3>
                <p>
                    基于信息化、物联网、人工智能等技术实现制造综合效率，减少生产损耗、降低原材料、库存、改善产品质量等。
                </p>
            </div>
            <div>
                <h3>智能化分析预警，提供决策支持</h3>
                <p>
                    利用大数据技术，实现生产、品质、物流设备状态等生产全过程管控，可视化展示生产、物流等情况，辅助企业领导决策。
                </p>
            </div>
            <div>
                <h3>为企业提供一站式解决方案</h3>
                <p>
                    在智能制造领域为企业提供全方位的整体解决方案，覆盖订单预测、产销平衡、订单承诺、生产计划、物料计划、生产备料等功能交付及售后服务。
                </p>
            </div>
        </div>
    </div>

    <!-- 核心能力 -->
    <div class="ability">
        <h2 class="CNtitle">核心能力</h2>
        <div class="abMain">
            <div class="topItem">
                <div>
                    <h3>SAP</h3>
                    <div class="list">
                        <div>
                            <p>销售预测</p>
                            <p>销售计划</p>
                            <p>销售订单</p>
                            <p>生产计划</p>
                            <p>物流信息</p>
                        </div>
                        <div>
                            <p>作业指导</p>
                            <p>合同管理</p>
                        </div>
                    </div>
                </div>
                <div>
                    <h3 class="pl85">OA</h3>
                    <div class="list">
                        <div>
                            <p>流程审批</p>
                            <p>人员班组</p>
                            <p>绩效管理</p>
                            <p>薪资管理</p>
                            <p>考勤管理</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="botItem">
                <div>
                    <h3>TMS</h3>
                    <div class="list">
                        <div>
                            <p>订单调度</p>
                            <p>排版管理</p>
                            <p>拆台管理</p>
                            <p>...</p>
                        </div>
                        <div>
                            <p>运力调度</p>
                            <p>车辆分配</p>
                            <p>车辆进场</p>
                            <p>...</p>
                        </div>
                        <div>
                            <p>厂内调度</p>
                            <p>车辆排队</p>
                            <p>月台分配</p>
                            <p>...</p>
                        </div>
                        <div>
                            <p>在途跟踪</p>
                            <p>硬件定位</p>
                            <p>物流接口</p>
                            <p>...</p>
                        </div>
                        <div>
                            <p>签收管理</p>
                            <p>多渠道签收</p>
                            <p>回单管理</p>
                            <p>...</p>
                        </div>
                    </div>
                </div>
                <div>
                    <h3>WMS</h3>
                    <div class="list">
                        <div>
                            <p>库存管理</p>
                            <p>成品库存</p>
                            <p>材料库存</p>
                            <p>库存盘点</p>
                            <p>...</p>
                        </div>
                        <div>
                            <p>入库管理</p>
                            <p>生产/采购入库</p>
                            <p>到货清点</p>
                            <p>入库单</p>
                            <p>...</p>
                        </div>
                        <div>
                            <p>出库管理</p>
                            <p>订单出库</p>
                            <p>出库清点</p>
                            <p>出库单</p>
                            <p>...</p>
                        </div>
                        <div>
                            <p>退货管理</p>
                            <p>产线退料</p>
                            <p>质检退回</p>
                            <p>销售退货</p>
                            <p>...</p>
                        </div>
                        <div>
                            <p>调拨管理</p>
                            <p>调拨计划</p>
                            <p>调拨入库</p>
                            <p>调拨出库</p>
                            <p>...</p>
                        </div>
                    </div>
                </div>
                <div class="topMidItem">
                    <h3>MES</h3>
                    <div class="list">
                        <div>
                            <p>生产计划</p>
                            <p>产线拍程</p>
                            <p>物流需求</p>
                            <p>班组需求</p>
                            <p>...</p>
                        </div>
                        <div>
                            <p>设备状态</p>
                            <p>制造过程数据</p>
                            <p>异常管理</p>
                            <p>维修保养</p>
                            <p>...</p>
                        </div>
                        <div>
                            <p>物料计划</p>
                            <p>采购预测</p>
                            <p>配套&比例</p>
                            <p>备品备件</p>
                            <p>...</p>
                        </div>
                        <div>
                            <p>产能平衡</p>
                            <p>产能可视化</p>
                            <p>供需匹配</p>
                            <p>S&OP发布</p>
                            <p>...</p>
                        </div>
                        <div>
                            <p>绩效分析</p>
                            <p>预测准确性</p>
                            <p>计划变动率</p>
                            <p>生产达成率</p>
                            <p>...</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <Side /> -->

    <Footer ref="Footer" />
</div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Nav from "@/components/nav.vue";
import Side from "@/components/side.vue";
export default {
    components: {
        Footer,
        Nav,
        Side
    },
    methods: {
        consult() {
            this.$refs.Footer.checkShow(2)
        }
    }
};
</script>

<style lang="scss" scoped>
//   上方海报
.poster {
    width: 100%;
    height: 620px;
    background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/制造业.png);
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .introduce {
        width: 800px;
        text-align: left;
        margin-left: 180px;
        box-sizing: border-box;

        h2 {
            font-size: 60px;
            font-weight: 400;
            color: #fff;
            line-height: 69px;
        }

        p {
            box-sizing: border-box;
            font-size: 22px;
            font-weight: 400;
            color: #fff;
            line-height: 33px;
            margin: 40px 0;
        }

        .el-button {
            width: 224px;
            height: 58px;
            background: #fb5604;
            border-radius: 8px;
            border: none;
            font-size: 24px;
            font-weight: 500;
            color: #ffffff;
            line-height: 31px;
        }
    }
}

// 方案优势
.advantage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;

    >div {
        display: flex;
        justify-content: space-between;
        width: 90%;

        >div {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 520px;
            height: 280px;
            border: 1px solid #dadada;
            padding: 20px 20px 50px 20px;
            box-sizing: border-box;

            h3 {
                font-size: 24px;
                font-weight: 500;
                color: #202020;
                line-height: 48px;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                color: #717171;
                line-height: 31px;
                text-align: left;
            }
        }
    }
}

// 方案优势
.ability {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px 0;

    .abMain {
        width: 90%;
        position: relative;
        margin-top: 80px;

        h3 {
            height: 80px;
            background: #497ACF;
            font-size: 36px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 80px;
            text-align: left;
            padding-left: 35px;
            box-sizing: border-box;
        }

        .list {
            display: flex;
            justify-content: space-around;
            height: 354px;
            background: #FFFFFF;
            border: 1px solid #DADADA;
            padding: 50px 20px;
            box-sizing: border-box;

            p {
                font-size: 20px;
                font-weight: 500;
                color: #222222;
                line-height: 48px;
            }
        }

        .topItem {
            display: flex;
            justify-content: space-between;
            margin-bottom: 50px;

            >div {
                width: 480px;
            }
        }

        .botItem {
            display: flex;
            justify-content: space-between;

            >div {
                width: 790px;
            }

            .topMidItem {
                width: 850px;
                position: absolute;
                left: 0;
                top: -60px;
                right: 0;
                bottom: 0;
                margin: auto;
            }
        }
    }
}
</style>
