var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('Nav'),_c('div',{staticClass:"poster"},[_c('div',{staticClass:"introduce"},[_c('h2',[_vm._v("大数据解决方案")]),_c('p',[_vm._v(" 基于大数据技术从各类数据中快速获得有价值的信息并进行呈现、共享等服务 能够帮助企业聚合内外部数据,支撑高效的数据服务，最终提升企业决策水平。 ")]),_c('el-button',{attrs:{"type":""},on:{"click":_vm.consult}},[_vm._v("立即咨询")])],1)]),_vm._m(0),_vm._m(1),_c('div',{staticClass:"area"},[_c('h2',{staticClass:"ENtitle"},[_vm._v("APPLICATION AREA")]),_c('h2',{staticClass:"CNtitle"},[_vm._v("应用领域")]),_c('div',{staticClass:"areaMain"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('div',[_c('el-button',{attrs:{"round":""}},[_vm._v("了解更多 ＞")])],1)])]),_vm._m(9),_c('div',{staticClass:"case"},[_c('h2',{staticClass:"ENtitle"},[_vm._v("APPLICATION AREA")]),_c('h2',{staticClass:"CNtitle"},[_vm._v("客户案列")]),_c('div',{staticClass:"caseMain"},[_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/大数据-客户案例-数据中台.png","alt":""}}),_c('div',{staticClass:"shujuzhongtai",on:{"click":function($event){return _vm.$router.push('/dataCenter')}}},[_vm._v(" 数据中台 ")])])])],1),_c('Footer',{ref:"Footer"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service"},[_c('h2',{staticClass:"ENtitle"},[_vm._v("CORE TECHNOLOGY")]),_c('h2',{staticClass:"CNtitle"},[_vm._v("产品与服务")]),_c('div',{staticClass:"serveMain"},[_c('div',[_c('h3',[_vm._v("数据中台")]),_c('p',[_vm._v("把原始数据转化为数据资产，快速构建数据服务，使企业可以持续、充分地利用数据，实现数据可见、可用、可运营的目标，以数据来驱动决策和运营，不断深化数字化转型。 ")]),_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/矢量智能对象.png","alt":""}})]),_c('div',[_c('h3',[_vm._v("大数据分析平台")]),_c('p',[_vm._v("集成企业内部运营支撑系统和外部数据，包括交易型大数据(Big Transaction Data)和交互型大数据(Big Interaction Data)通过多种云计算的技术将之集成和处理，向企业内部和外部企业客户提供有极大商业价值的信息支撑和智能解决方案。")]),_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/矢量智能对象2.png","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"architecture"},[_c('h2',{staticClass:"ENtitle"},[_vm._v("SMART CITY BIG DATA APPLICATION TECHNOLOGY ARCHITECTURE")]),_c('h2',{staticClass:"CNtitle"},[_vm._v("智慧城市大数据应用技术架构")]),_c('div',{staticClass:"arcMain"},[_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/智慧城市大数据应用技术架构.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/data-金融.png","alt":""}}),_c('p',[_vm._v("金融领域")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/data-物流.png","alt":""}}),_c('p',[_vm._v("物联网和物流领域")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/data-公共.png","alt":""}}),_c('p',[_vm._v("公共服务领域")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/data-医疗.png","alt":""}}),_c('p',[_vm._v("医疗领域")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/data-能源.png","alt":""}}),_c('p',[_vm._v("能源领域")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/data-政务.png","alt":""}}),_c('p',[_vm._v("政务领域")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/data-司法.png","alt":""}}),_c('p',[_vm._v("司法领域")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scheme"},[_c('h2',{staticClass:"ENtitle"},[_vm._v("SCHEME ADVANTAGES")]),_c('h2',{staticClass:"CNtitle"},[_vm._v("方案优势")]),_c('div',{staticClass:"schemeMain"},[_c('div',[_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/data-场景.png","alt":""}}),_c('div',{staticClass:"itemText"},[_c('h3',[_vm._v("应用场景丰富")]),_c('p',[_vm._v("可应用于金融、物联网、物流、政府、司法、数字经济、保险等多个领域，实现数据生命周期管理、数据共享等服务。")])])]),_c('div',[_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/data-体系.png","alt":""}}),_c('div',{staticClass:"itemText"},[_c('h3',[_vm._v("全面的服务体系")]),_c('p',[_vm._v("提供全生命周期的服务体系，为客户量身定制从分析、规划、设计、研发、售后等特色服务及解决方案。 ")])])]),_c('div',[_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/data-方案.png","alt":""}}),_c('div',{staticClass:"itemText"},[_c('h3',[_vm._v("成熟的项目落地方案")]),_c('p',[_vm._v("已为上百家客户提供专业解决方案，遵循全球公认的ISO质量体系，精细打磨每一个细节。 ")])])])])])
}]

export { render, staticRenderFns }