<template>
<div>
    <!-- 头部导航 -->
    <Nav />

    <!-- 上方海报 -->
    <div class="poster">
        <div class="introduce">
            <!-- <el-button type="">立即咨询</el-button> -->
        </div>
    </div>

    <!-- 公司简介 -->
    <div class="profile">
        <h2 class="ENtitle">COMPANY PROFILE</h2>
        <h2 class="CNtitle">公司简介</h2>
        <div class="profileMain">
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/介绍.png" alt="" />
            <div class="mainR">
                <div class="topImg">
                    <div>
                        <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/13.png" alt="" />
                        <p>核心团队</p>
                    </div>
                    <div>
                        <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/40.png" alt="" />
                        <p>行业经验</p>
                    </div>
                </div>
                <p>
                    武汉泰铭恒创信息技术股份有限公司是湖北本土的IT服务商。业务方向涉及云计算、大数据、移动互联网、物联网、信息安全等领域，业务领域涵盖IT产品化服务、应用软件开发、系统集成等多种IT服务业务，是中国最早提出IT服务产品化的公司。基于“客户导向”的经营理念以及“合作共赢”的发展战略，泰铭恒创立足于大华中市场，以为企业及政府客户提升IT核心能力为使命，以卓越的解决方案、对客户业务的深刻理解以及遍布大华中区域的高效密集的服务交付网络，为客户提供贯穿其IT建设整个生命周期的“-
                    -站式”服务。公司在公安、电信、邮政、金融、制造、物联网等领域拥有大量成功案例。泰铭恒创也是一家有活力的年轻化网络科技公司，除了年轻和激情，我们拥有移动互联网行业领域策划、设计、开发、推广、运营的多年经验，有足够的信心为客户提供具有市场潜力、能改变人们生活方式、能影响整个行业发展的软件产品与服务。
                </p>
            </div>
        </div>
    </div>

    <!-- 荣誉资质 -->
    <div class="honor">
        <h2 class="ENtitle">HONOR AND QUALIFUCATION</h2>
        <h2 class="CNtitle">荣誉资质</h2>
        <div class="honorMain">
            <Swiper />
        </div>
    </div>

    <!-- 服务保障 -->
    <div class="guarantee">
        <h2 class="ENtitle">SERVICE GUARANTEE SYSTEM</h2>
        <h2 class="CNtitle">服务保障</h2>
        <div class="guaranteeMain">
            <!-- <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/椭圆 2 拷贝 10.png" alt="" /> -->
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/服务保障体系.jpg" alt="" />
        </div>
    </div>

    <!-- <Side /> -->
    <Footer />
</div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Nav from "@/components/nav.vue";
import Side from "@/components/side.vue";
import Swiper from "@/components/swiper.vue";
export default {
    components: {
        Footer,
        Nav,
        Side,
        Swiper
    },
    data() {
        return {
            activeArea: 0,
        };
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
//   上方海报
.poster {
    width: 100%;
    height: 620px;
    background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/公司介绍.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

// 公司简介
.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;

    .profileMain {
        display: flex;
        justify-content: space-between;
        width: 90%;

        >img {
            width: 600px;
            height: 490px;
        }

        .mainR {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 960px;

            .topImg {
                display: flex;

                div {
                    width: 300px;
                    text-align: left;
                }

                img {
                    width: 140px;
                    height: 120px;
                }

                p {
                    font-size: 24px;
                    font-weight: 400;
                    color: #202020;
                    line-height: 30px;
                }
            }
        }

        p {
            font-size: 16px;
            font-weight: 400;
            color: #717171;
            line-height: 31px;
            text-align: left;
        }

        .el-button {
            width: 177px;
            height: 52px;
            border: 2px solid #fd5207;
            border-radius: 10px;
        }
    }
}

// 荣誉资质
.honor {
    padding-top: 135px;

    .honorMain {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 500px;
        padding: 0 5%;
        box-sizing: border-box;

        .swiper {
            width: 100%;
        }

        .el-carousel__item {
            display: flex;
            justify-content: space-around;
        }

        .el-carousel__item h3 {
            color: #475669;
            font-size: 18px;
            opacity: 0.75;
            // line-height: 600px;
            margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
            background-color: #99a9bf;
        }

        .el-carousel__item:nth-child(2n + 1) {
            background-color: #d3dce6;
        }
    }
}

// 服务保障系统
.guarantee {
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
    box-sizing: border-box;

    .guaranteeMain {
        width: 90%;

        img {
            width: 100%;
            height: 600px;
        }
    }
}
</style>
