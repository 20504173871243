<template>
<div>
    <div class="formwork">
        <Nav />
        <!-- 上方海报 -->
        <div class="poster bg7">
            <div class="back" @click="back">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/返回.png" alt="">
                <h2>
                    返回案例
                </h2>
            </div>
            <el-button @click="consult">立即咨询</el-button>
        </div>
        <div class="main">
            <div class="bground">
                <h2>项目背景</h2>
                <p> 为某公安局定制化开发公安合成作战体系指挥方面的应用平台。着力构建情报信息更灵敏、指挥调度更科学、资源整合更紧密的情指行一体化警务中枢，全面推动公安工作质量变革、效率变革、动力变革。</p>
            </div>
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <div class="painPoint">
                <h2>项目痛点</h2>
                <p> 如何利用有限的警力更好地提升当地群众的获得感、幸福感、安全感。
                    <br>
                    大数据与情报转化力的不足，导致基层单位数据采集和整合标准不符合规范，重要信息要素缺失。
                    <br>
                    警种部门之间的壁垒，数据信息难以实现跨警种、跨部门的实时共享。</p>
            </div>
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <div class="solve">
                <h2>解决方案</h2>
                <p> 平台是公安合成作战体系中指挥方面的具体应用平台，是前述信息资源服务和各项应用的落地和实现。融合通信和信息整合是该平台的核心技术，是多种通信手段与指挥信息融合的技术保障，为指挥人员提供必要的信息支撑，提高了指挥效率。
                    <br>
                    充分整合刑侦、情报、技侦、网侦、视侦等专业手段和资源优势，围绕实时警情和突出治安问题，积极组织各区域及有关部门整体协作，同步上案、信息互通、无缝对接，实现“共享合成”，构建统一指挥、快速反应、上下联动、紧密有序、优势互补的常态化合成作战工作机制。</p>
                <img class="computer" src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/情指一体化.png" alt="">
            </div>
            <div class="achievement">
                <h2>项目成就</h2>
                <p> 让信息资源真正实现“一图展示、一网共享、一站流转、一键搜索”，发挥情报指挥中心服务支撑最大化，达到为基层派出所提效减负的实效。</p>
            </div>
        </div>
    </div>

    <!-- <Side /> -->


    <Footer ref="Footer" />
</div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Nav from "@/components/nav.vue";
import Side from "@/components/side.vue";
export default {
    components: {
        Footer,
        Nav,
        Side
    },
    mounted() {
        this.showWhat = sessionStorage.getItem("id")
        window.scrollTo(0, 0)
    },
    data() {
        return {
            showWhat: null
        }
    },
    methods: {
        back() {
            this.$router.push("/case")
        },
        consult() {
            this.$refs.Footer.checkShow(2)
        }
    }
}
</script>

<style lang="scss" scoped>
.formwork {
    display: flex;
    flex-direction: column;
    align-items: center;
}

//   上方海报
.poster {
    position: relative;
    width: 100%;
    height: 620px;
    background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/情指化banner.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-bottom: 50px;

    .back {
        display: flex;
        align-items: center;
        padding: 20px 0 0 150px;
        cursor: pointer;

        img {
            width: 30px;
            height: 30px;
            font-size: 50px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 55px;
            margin-right: 10px;
        }

        h2 {
            font-size: 29px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 55px;
        }
    }

    .el-button {
        position: absolute;
        top: 500px;
        left: 180px;
        width: 203px;
        height: 50px;
        background: linear-gradient(0deg, #F28E26, #FD644F);
        font-size: 25px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
        border-radius: 40px;
        border: none;
    }
}

.main {
    width: 90%;

    >div {
        text-align: left;
        margin-bottom: 110px;

        h2 {
            padding-right: 50px;
            box-sizing: border-box;
            text-align: center;
            width: 260px;
            height: 80px;
            background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/框.png);
            background-size: 100%;
            background-repeat: no-repeat;
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #202020;
            line-height: 80px;
            margin-bottom: 40px;
        }

        p {
            width: 65%;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 31px;
            padding-left: 50px;
            box-sizing: border-box;
        }
    }

    .list {
        margin-top: 45px;

        div {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            img {
                width: 30px;
                height: 30px;
                margin-right: 10px;
            }

            h3 {
                padding-top: 5px;
                font-size: 21px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #202020;
                line-height: 30px;
                box-sizing: border-box;
            }

        }

        ul {
            padding-left: 60px;

            li {
                width: 50%;
                list-style-type: decimal;
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #666666;
                line-height: 33px;
            }
        }

    }

    .computer {
        width: 530px;
        height: 434px;
        margin: 30px 0 0 60px;
    }
}
</style>
