import { render, staticRenderFns } from "./artificialIntelligence.vue?vue&type=template&id=56b7bb28&scoped=true&"
import script from "./artificialIntelligence.vue?vue&type=script&lang=js&"
export * from "./artificialIntelligence.vue?vue&type=script&lang=js&"
import style0 from "./artificialIntelligence.vue?vue&type=style&index=0&id=56b7bb28&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56b7bb28",
  null
  
)

export default component.exports