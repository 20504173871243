<template>
<div class="smartCity">
    <Nav />
    <!-- 上方海报 -->
    <div class="poster">
        <div class="introduce">
            <h2>智慧城市</h2>
            <p>提供智慧城市专业解决方案，基于信息技术帮助政府客户构建智慧城市标准规范、
                顶层设计、智慧应用、数据共享等全周期服务、整合生态资源，优化城市功能，提
                高市民生活质量。</p>
            <el-button type="" @click="consult">立即咨询</el-button>
        </div>
    </div>
    <ProTop />
    <div class="cityMain">
        <div class="schemeDiagram">
            <h2 class="title">智慧城市解决方案</h2>
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/smartcity_bg.png" alt="">
            <!-- <h2 class="title">智慧城市解决方案</h2>
            <h3>服务对象</h3>
            <div class="objItem">
                <p>城市决策者</p>
                <p>相关部门</p>
                <p>社会企业</p>
                <p>城市市民</p>
            </div>
            <h3>智慧城市顶层规划</h3>
            <h4>智慧城市运行综合指挥中心</h4>
            <div class="commandMain">
                <div>
                    <h5>城市大脑</h5>
                    <p>感知/监测/预警/可视化/分析/洞察</p>
                </div>
                <div>
                    <h5>城市事件治理</h5>
                    <p>接报/分拨/处置</p>
                </div>
                <div>
                    <h5>城市应急指挥调度中心</h5>
                    <p>预案/指挥/资源/调度</p>
                </div>
            </div>
            <h3>智慧应用</h3>
            <div class="appMain">
                <div>
                    <h4>城市治理</h4>
                    <p>可信服务</p>
                    <p>智慧政务</p>
                    <p>智慧公安</p>
                    <p>......</p>
                </div>
                <div>
                    <h4>城市治理</h4>
                    <p>可信服务</p>
                    <p>智慧政务</p>
                    <p>智慧公安</p>
                    <p>......</p>
                </div>
                <div>
                    <h4>城市治理</h4>
                    <p>可信服务</p>
                    <p>智慧政务</p>
                    <p>智慧公安</p>
                    <p>......</p>
                </div>
                <div>
                    <h4>城市治理</h4>
                    <p>可信服务</p>
                    <p>智慧政务</p>
                    <p>智慧公安</p>
                    <p>......</p>
                </div>
            </div>
            <h3>基础平台</h3>
            <div class="platformMain">
                <div class="platformItem">
                    <div>
                        <h4>业务中台</h4>
                        <p>统一指挥、业务融合</p>
                    </div>
                    <div>
                        <h4>业务中台</h4>
                        <p>统一指挥、业务融合</p>
                    </div>
                    <div>
                        <h4>业务中台</h4>
                        <p>统一指挥、业务融合</p>
                    </div>
                    <div>
                        <h4>业务中台</h4>
                        <p>统一指挥、业务融合</p>
                    </div>
                </div>
                <div class="botItem">
                    <h4>技术支撑平台</h4>
                    <p>数据孪生服务、AI算法服务、区块链服务、数据可视化服务、城市边缘计算服务……</p>
                </div>
            </div>
            <h3>基础建设</h3>
            <div class="Infrastructure">
                <div class="InfrastructureTop">
                    <h4>感知终端</h4>
                    <div>
                        <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/组 92.png" alt="">
                        <div class="imgNames">
                            <h5>个人终端</h5>
                            <h5>城市公共基础设施</h5>
                        </div>
                    </div>
                </div>
                <div class="InfrastructureBot">
                    <div>基础网络</div>
                    <div>信息安全</div>
                    <div>标准规范</div>
                    <div>城市基础数据源</div>
                    <div>政企云网共有能力</div>
                </div>
            </div> -->
            <h2 class="title">平台优势</h2>
            <div class="advantage">
                <div>
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/快速.png" alt="">
                    <h5>快速的应用提供能力</h5>
                </div>
                <div>
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/第三方.png" alt="">
                    <h5>第三方系统集成能力</h5>
                </div>
                <div>
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/数据看板.png" alt="">
                    <h5>数据统一分析能力</h5>
                </div>
                <div>
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/资源共享.png" alt="">
                    <h5>系统资源共享能力</h5>
                </div>
                <div>
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/盾牌.png" alt="">
                    <h5>统一硬件/存储/安全解决能力</h5>
                </div>
                <div>
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/系统平台.png" alt="">
                    <h5>系统平台演进能力</h5>
                </div>
            </div>
            <h2 class="title">客户案例</h2>
            <div class="CustomerCase">
                <a href="../businessCenter">
                    <div class="zhongtai">
                        <p>业务中台</p>
                    </div>
                </a>
                <a href="../safeCommunity">
                    <div class="shequ">
                        <p>智慧平安社区</p>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <!-- <Side /> -->

    <Footer ref="Footer" />
</div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Nav from "@/components/nav.vue";
import ProTop from "../../components/productService/proTop.vue";
import Side from "@/components/side.vue";
export default {
    components: {
        Footer,
        Nav,
        ProTop,
        Side
    },
    methods: {
        consult() {
            this.$refs.Footer.checkShow(2)
        }
    }
};
</script>

<style lang="scss" scoped>
//   上方海报
.poster {
    width: 100%;
    height: 620px;
    background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/home_swiper2.png);
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .introduce {
        width: 800px;
        text-align: left;
        margin-left: 180px;
        box-sizing: border-box;

        h2 {
            font-size: 60px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #fff;
            line-height: 69px;
        }

        p {
            box-sizing: border-box;
            font-size: 22px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #fff;
            line-height: 33px;
            margin: 40px 0;
        }

        .el-button {
            width: 224px;
            height: 58px;
            background: #FB5604;
            border-radius: 8px;
            border: none;
            font-size: 24px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 31px;
        }
    }
}

.smartCity {
    display: flex;
    flex-direction: column;
    align-items: center;

    .cityMain {
        width: 80%;
        align-self: center;
        // -----
        margin-bottom: 80px;

        .schemeDiagram {

            h3:nth-child(2) {
                font-size: 30px;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 60px;
                height: 60px;
                background: #5B71E9;
            }

            .objItem {
                display: flex;
                justify-content: space-around;
                align-items: center;
                height: 90px;
                background: #DBECFE;
                font-size: 26px;
                font-weight: 500;
                color: #5B71E9;
            }

            h3:nth-child(4) {
                height: 115px;
                background: #56CCE5;
                font-size: 30px;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 115px;
                margin: 10px 0;
            }

            h4:nth-child(5) {
                height: 60px;
                font-size: 30px;
                background: #56E5B9;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 60px;
            }

            .commandMain {
                display: flex;
                justify-content: space-around;
                margin-bottom: 10px;

                div {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    width: 33.33%;
                    height: 160px;
                    background: #DDFBF1;
                    font-weight: 500;
                    color: #12A87A;
                    line-height: 30px;

                    h5 {
                        font-size: 30px;
                    }

                    p {
                        font-size: 26px;
                    }
                }
            }

            h3:nth-child(7) {
                height: 60px;
                background: #479AFF;
                font-size: 30px;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 60px;
                margin: 10px 0;
            }

            .appMain {
                display: flex;
                justify-content: space-around;
                align-items: center;
                height: 339px;
                background: #CFE5FF;
                margin-bottom: 10px;

                div {
                    h4 {
                        font-size: 30px;
                        font-weight: 500;
                        color: #479AFF;
                        margin-bottom: 20px;
                    }

                    p {
                        width: 253px;
                        height: 47px;
                        background: #FFFFFF;
                        font-size: 24px;
                        font-weight: 500;
                        color: #479AFF;
                        line-height: 47px;
                        margin-bottom: 10px;
                    }
                }
            }

            h3:nth-child(9) {
                height: 60px;
                background: #56CCE5;
                font-size: 30px;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 60px;
            }

            .platformMain {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                height: 543px;
                background: #E3FAFF;

                .platformItem {
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;

                    div {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        align-items: center;
                        width: 640px;
                        height: 130px;
                        background: #FFFFFF;
                        border: 1px solid #56CCE5;
                        margin-bottom: 20px;
                        font-weight: 500;
                        color: #11AACA;
                        line-height: 30px;

                        h4 {
                            font-size: 30px;
                        }

                        p {
                            font-size: 26px;
                        }
                    }
                }

                .botItem {
                    align-self: center;
                    width: 1400px;
                    height: 130px;
                    background: #FFFFFF;
                    border: 1px solid #56CCE5;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    font-weight: 500;
                    color: #11AACA;
                    line-height: 30px;

                    h4 {
                        font-size: 30px;
                    }

                    p {
                        font-size: 26px;
                    }
                }
            }

            h3:nth-child(11) {
                height: 60px;
                background: #8296F9;
                font-size: 30px;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 60px;
            }

            .Infrastructure {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                height: 470px;
                background: #E5EAFD;
                padding: 0 20px;
                box-sizing: border-box;

                .InfrastructureTop {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    height: 251px;
                    background: #FFFFFF;
                    border: 1px solid #8296F9;
                    font-size: 30px;
                    font-weight: 500;
                    color: #8296F9;
                    line-height: 30px;
                    padding: 20px 40px;
                    box-sizing: border-box;

                    img {
                        width: 100%;
                        height: 100px;
                    }

                    .imgNames {
                        display: flex;
                        justify-content: space-around;
                    }
                }

                .InfrastructureBot {
                    display: flex;
                    justify-content: space-between;

                    div {
                        width: 280px;
                        height: 90px;
                        background: #FFFFFF;
                        border: 1px solid #8296F9;
                        font-size: 30px;
                        font-weight: 500;
                        color: #8296F9;
                        line-height: 90px;
                    }
                }

            }

            .advantage {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;

                div {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    width: 480px;
                    height: 270px;
                    background: #FFFFFF;
                    border: 1px solid #DADADA;
                    margin-bottom: 20px;
                    border-bottom: 8px solid #FB5604;
                    padding-top: 20px;
                    box-sizing: border-box;

                    img {
                        width: 100px;
                        height: 100px;
                    }

                    h5 {
                        font-size: 30px;
                        font-weight: 500;
                        color: #202020;
                        line-height: 48px;
                    }
                }
            }

            .CustomerCase {
                display: flex;
                justify-content: space-between;

                div {
                    display: flex;
                    align-items: flex-end;
                    width: 740px;
                    height: 350px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;

                    p {
                        width: 100%;
                        height: 60px;
                        font-size: 30px;
                        font-weight: 500;
                        color: #202020;
                        line-height: 60px;
                        background: #FFFFFF;
                        opacity: 0.58;
                    }
                }

                .zhongtai {
                    background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/智慧城市-业务中台.png);
                }

                .shequ{
                    background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/客户案例1.png);
                }
            }
        }

        img {
            width: 100%;
        }
    }
}
</style>
