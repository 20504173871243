<template>
<div class="business">
    <Nav />

    <!-- 上方海报 -->
    <div class="poster">
        <div class="introduce">
            <h2>电子商务解决方案</h2>
            <p>
                提供企业电子商务解决方案，针对多应用场景，提供人力资源、市场营销、供应链光临、
                客户管理、物流管理、业务流程重组、财务管理等服务，基于AI数据分析、物联网、大
                数据及行业应用能力，为政府和企业实现线上线下销售、企业内控等整体解决方案。
            </p>
            <el-button type="" @click="consult">立即咨询</el-button>
        </div>
    </div>

    <!-- 产品作用 -->
    <h6>方案优势</h6>
    <div class="ProductFunction1">
        <div class="funItem">
            <h2>功能强大，省时省力</h2>
            <p>
                集CRM、销售平台、物流平台等丰富多样的一体化电子商务解决方案与一体，成熟的系统架构，具备高扩展性，提供企业省时省力的一站式服务体验。
            </p>
        </div>
        <div class="funItem">
            <h2>态势感知大脑，辅助决策</h2>
            <p>
                利用大数据可视化、物联网、人工智能等技术，实现设备与数据可控的全面感知，提高企业管理能力。
            </p>
        </div>
        <div class="funItem">
            <h2>专业可靠的开发团队</h2>
            <p>
                十多年开发经验的专业团队，精准把控整个开发流程。完善的售后体系，严谨的项目管理。
            </p>
        </div>
    </div>

    <h6>核心能力</h6>
    <img class="coreImg" src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com//image/商业内容.png" alt="">

    <!-- <Side /> -->

    <Footer ref="Footer" />
</div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Nav from "@/components/nav.vue";
import Side from "@/components/side.vue";
export default {
    components: {
        Footer,
        Nav,
        Side
    },
    methods: {
        consult() {
            this.$refs.Footer.checkShow(2)
        }
    }
}
</script>

<style lang="scss" scoped>
.business {
    display: flex;
    flex-direction: column;
    align-items: center
}

.poster {
    width: 100%;
    height: 620px;
    background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com//image/电子商务.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;

    .introduce {
        width: 800px;
        text-align: left;
        margin-left: 180px;
        box-sizing: border-box;

        h2 {
            font-size: 60px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 69px;
            margin-bottom: 40px;
        }

        p {
            font-size: 22px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 33px;
        }

        .el-button {
            margin-top: 60px;
            width: 224px;
            height: 58px;
            background: #fb5604;
            border-radius: 8px;
            border: none;
            font-size: 24px;
            font-weight: 500;
            color: #ffffff;
            line-height: 31px;
        }
    }
}

h6 {
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #202020;
    line-height: 30px;
    margin: 80px 0 40px 0;
}

.ProductFunction1 {
    width: 80%;
    display: flex;
    justify-content: space-between;

    .funItem {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 480px;
        height: 280px;
        border: 1px solid #dadada;
        box-sizing: border-box;
        padding: 20px 35px;

        h2 {
            font-size: 24px;
            font-weight: 500;
            color: #202020;
            line-height: 48px;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            color: #717171;
            line-height: 31px;
            text-align: left;
        }
    }
}

.coreImg {
    width: 80%;
    margin-bottom: 40px;
}
</style>
