<template>
    <div class="thumb-example">
        <!-- swiper1 -->
        <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
            <swiper-slide class="slide-1"></swiper-slide>
            <swiper-slide class="slide-2"></swiper-slide>
            <swiper-slide class="slide-3"></swiper-slide>
            <swiper-slide class="slide-4"></swiper-slide>
            <swiper-slide class="slide-5"></swiper-slide>
            <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
            <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
        </swiper>
        <!-- swiper2 Thumbs -->
        <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
            <swiper-slide class="slide-1"></swiper-slide>
            <swiper-slide class="slide-2"></swiper-slide>
            <swiper-slide class="slide-3"></swiper-slide>
            <swiper-slide class="slide-4"></swiper-slide>
            <swiper-slide class="slide-5"></swiper-slide>
            <swiper-slide class="slide-6"></swiper-slide>
            <swiper-slide class="slide-7"></swiper-slide>
            <!-- <swiper-slide class="slide-8"></swiper-slide>
            <swiper-slide class="slide-9"></swiper-slide>
            <swiper-slide class="slide-10"></swiper-slide>
            <swiper-slide class="slide-11"></swiper-slide>
            <swiper-slide class="slide-12"></swiper-slide>
            <swiper-slide class="slide-13"></swiper-slide>
            <swiper-slide class="slide-14"></swiper-slide>
            <swiper-slide class="slide-15"></swiper-slide>
            <swiper-slide class="slide-16"></swiper-slide>
            <swiper-slide class="slide-17"></swiper-slide>
            <swiper-slide class="slide-18"></swiper-slide>
            <swiper-slide class="slide-19"></swiper-slide> -->
        </swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
    name: 'swiper-example-thumbs-gallery',
    title: 'Thumbs gallery with Two-way control',
    components: {
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            swiperOptionTop: {
                loop: true,
                loopedSlides: 5, // looped slides should be the same
                spaceBetween: 10,
                // 左右两边的点击事件
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            swiperOptionThumbs: {
                loop: true, // 是否可循环
                loopedSlides: 5, // looped slides should be the same
                spaceBetween: 10, // 缩略图之间的间隙大小
                centeredSlides: true, // 大图对应的缩略图位置显示在中间
                slidesPerView: 5, // 每一页显示下方缩略图的数量，auto ：自动显示所有数量；输入number（如1、2、3等）则是手动定义显示的数量
                touchRatio: 0.2, // 触控比例，可理解为拖动缩略图的距离，默认值为1
                slideToClickedSlide: true //点击其他缩略图可跳转
            }
        };
    },
    mounted() {
        this.$nextTick(() => {
            const swiperTop = this.$refs.swiperTop.$swiper;
            const swiperThumbs = this.$refs.swiperThumbs.$swiper;
            swiperTop.controller.control = swiperThumbs;
            swiperThumbs.controller.control = swiperTop;
        });
    }
};
</script>
<style lang="scss" scoped>
.thumb-example {
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.swiper {
    margin-bottom: 10px;
    .swiper-slide {
        // background-size: cover;
        background-position: center;

        &.slide-1 {
            background-image: url('http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/情指一体化.png'); //图片地址
        }
        &.slide-2 {
            background-image: url('http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/卡口布控.png'); //图片地址
        }
        &.slide-3 {
            background-image: url('http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/卡口布控.png'); //图片地址
        }
        &.slide-4 {
            background-image: url('http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/卡口布控.png'); //图片地址
        }
        &.slide-5 {
            background-image: url('http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/卡口布控.png'); //图片地址
        }
        &.slide-6 {
            background-image: url('http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/卡口布控.png'); //图片地址
        }
        &.slide-7 {
            background-image: url('http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/卡口布控.png'); //图片地址
        }
        // &.slide-8 {
        //     background-image: url('../assets/image/图片8.png'); //图片地址
        // }
        // &.slide-9 {
        //     background-image: url('../assets/image/图片9.png'); //图片地址
        // }
        // &.slide-10 {
        //     background-image: url('../assets/image/图片10.png'); //图片地址
        // }
        // &.slide-11 {
        //     background-image: url('../assets/image/图片11.png'); //图片地址
        // }
        // &.slide-12 {
        //     background-image: url('../assets/image/图片12.png'); //图片地址
        // }
        // &.slide-13 {
        //     background-image: url('../assets/image/图片13.png'); //图片地址
        // }
        // &.slide-14 {
        //     background-image: url('../assets/image/图片14.png'); //图片地址
        // }
        // &.slide-15 {
        //     background-image: url('../assets/image/图片15.png'); //图片地址
        // }
        // &.slide-16 {
        //     background-image: url('../assets/image/图片16.png'); //图片地址
        // }
        // &.slide-17 {
        //     background-image: url('../assets/image/图片17.png'); //图片地址
        // }
        // &.slide-18 {
        //     background-image: url('../assets/image/图片18.png'); //图片地址
        // }
        // &.slide-19 {
        //     background-image: url('../assets/image/图片19.png'); //图片地址
        // }
    }

    &.gallery-top {
        height: 80%;
        width: 100%;
        display: none;
    }
    &.gallery-thumbs {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding: gap 0;
        .swiper-wrapper {
            width: 100%;
            height: 100%;
        }
    }
    &.gallery-thumbs .swiper-slide { //等比例缩小
        opacity: 0.3;
        height: 100%;
        width: 68px;
        border: 1px solid #eee;
        background-size: contain;
        background-repeat: no-repeat;
    }
    &.gallery-thumbs .swiper-slide-active {
        opacity: 1;
    }
}
</style>
