<template>
<div>
    <div class="formwork">
        <Nav />
        <!-- 上方海报 -->
        <div class="poster bg2">
            <div class="back" @click="back">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/返回.png" alt="">
                <h2>
                    返回案例
                </h2>
            </div>
            <el-button @click="consult">立即咨询</el-button>
        </div>
        <div class="main">
            <div class="bground">
                <h2>项目背景</h2>
                <p> 基于某厂区对设备常会产生计量数据，运行状态、参数和故障等海量数据。通过数据中心将这些海量数据进行数据汇聚，数据标准化，数据治理，形成数据资产；</p>
            </div>
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <div class="painPoint">
                <h2>项目痛点</h2>
                <p> 数据孤岛问题：着信息化的深入，在传统烟囱式IT建设方式下，企业独立采购或者自建的各种企业信息系统，在内部形成诸多数据孤岛；
                    <br>
                    分散各处难以融合的数据，无法很好地支撑企业经营决策，也无法很好地应对快速变化的前端业务。</p>
            </div>
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <div class="solve">
                <h2>解决方案</h2>
                <p> 具备模型创建、作业管理、任务计划、定时调度、日志管理、数据加工、脚本植入等功能。
                    <br>
                    用于大数据信息的清洗加工，提供完整、便捷的解决方法。
                    <br>
                    并可以基于此平台构建大数据相关应用，形成采集、清洗、加工、分析、应用等为一体的的解决方案。</p>
                <img class="computer" src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/数据中台.png" alt="">
            </div>
            <div class="achievement">
                <h2>项目成就</h2>
                <p> 通过数据中心将这些海量数据进行数据汇聚，数据标准化，数据治理，形成数据资产；通过大数据存储可以有效的解决存储横向扩展；大数据分析，利用数据中心的大数据，建立大数据中心，将数学模型、 统计分析融入到大数据分析中，通过不断的模拟和学习，提升系统的实用化和智能化，提高厂区工业设备管理的数智化水平。</p>
            </div>
        </div>
    </div>

    <!-- <Side /> -->


    <Footer ref="Footer" />
</div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Nav from "@/components/nav.vue";
import Side from "@/components/side.vue";
export default {
    components: {
        Footer,
        Nav,
        Side
    },
    mounted() {
        this.showWhat = sessionStorage.getItem("id")
        window.scrollTo(0, 0)
    },
    data() {
        return {
            showWhat: null
        }
    },
    methods: {
        back() {
            this.$router.push("/case")
        },
        consult() {
            this.$refs.Footer.checkShow(2)
        }
    }
}
</script>

<style lang="scss" scoped>
.formwork {
    display: flex;
    flex-direction: column;
    align-items: center;
}

//   上方海报
.poster {
    position: relative;
    width: 100%;
    height: 620px;
    background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/数据中台banner.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-bottom: 50px;

    .back {
        display: flex;
        align-items: center;
        padding: 20px 0 0 150px;
        cursor: pointer;

        img {
            width: 30px;
            height: 30px;
            font-size: 50px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 55px;
            margin-right: 10px;
        }

        h2 {
            font-size: 29px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 55px;
        }
    }
    .el-button {
        position: absolute;
        top: 500px;
        left: 180px;
        width: 203px;
        height: 50px;
        background: linear-gradient(0deg, #F28E26, #FD644F);
        font-size: 25px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
        border-radius: 40px;
        border: none;
    }
}

.main {
    width: 90%;

    >div {
        text-align: left;
        margin-bottom: 110px;

        h2 {
            padding-right: 50px;
            box-sizing: border-box;
            text-align: center;
            width: 260px;
            height: 80px;
            background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/框.png);
            background-size: 100%;
            background-repeat: no-repeat;
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #202020;
            line-height: 80px;
            margin-bottom: 40px;
        }

        p {
            width: 65%;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 31px;
            padding-left: 50px;
            box-sizing: border-box;
        }
    }

    .list {
        margin-top: 45px;

        div {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            img {
                width: 30px;
                height: 30px;
                margin-right: 10px;
            }

            h3 {
                padding-top: 5px;
                font-size: 21px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #202020;
                line-height: 30px;
                box-sizing: border-box;
            }

        }

        ul {
            padding-left: 60px;

            li {
                width: 50%;
                list-style-type: decimal;
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #666666;
                line-height: 33px;
            }
        }

    }

    .computer {
        width: 530px;
        height: 434px;
        margin: 30px 0 0 60px;
    }
}
</style>
