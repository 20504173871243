<template>
<div class="enterpriseServices">
    <Nav />

    <!-- 上方海报 -->
    <div class="poster">
        <div class="introduce">
            <h2>企业数字化转型解决方案</h2>
            <p>提供企业信息化及数字化转型解决方案，基于云计算、大数据、人工智能、区块链等数字化技术解决企业业务痛点，推动企业运营模式、业务模式、组织架构等变革，实现运营和管理数字化,提升企业核心竞争力。</p>
            <el-button type="" @click="consult">立即咨询</el-button>
        </div>
    </div>
    <div class="scheme">
        <!-- <h2 class="ENtitle">Advantageous scheme</h2> -->
        <h2 class="CNtitle">方案优势</h2>
        <div class="schemeMain">
            <!-- <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/企业服务-方案优势.png" alt=""> -->
            <div class="schemeMainbox">
                <h3>高新技术推动企业数字化建设</h3>
                <p>拥有前沿的高新技术支撑企业解决方案，涉及区块链、大数据、人工智能等多个领域、深入结合企业原有生产要素为企业提供数字化转型关键能力。</p>
            </div>
            <div class="schemeMainbox">
                <h3>提升企业数据价值</h3>
                <p>利用数字化技术推动企业业务的数据转资与融合共享，实现企业的数字资产累计，内外资源高效整合，提升企业的管控、运营、服务等多方能力，为企业发展赋能。</p>
            </div>
            <div class="schemeMainbox">
                <h3>专业可靠的开发团队</h3>
                <p>十多年开发经验的专业团队，精准把控整个开发流程。完善的售后体系，严谨的项目管玛</p>
            </div>
        </div>
    </div>

    <div class="scheme">
        <!-- <h2 class="ENtitle">Core competence</h2> -->
        <h2 class="CNtitle">核心能力</h2>
        <div class="schemeMain">
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/企业服务-核心能力2.png" alt="">
        </div>
    </div>

    <!-- <Side /> -->

    <Footer ref="Footer" />
</div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Nav from "@/components/nav.vue";
import Side from "@/components/side.vue";
export default {
    components: {
        Footer,
        Nav,
        Side
    },
    methods: {
        consult() {
            this.$refs.Footer.checkShow(2)
        }
    }
};
</script>

<style lang="scss" scoped>
h2 {
    color: #fff;
}

.enterpriseServices {
    // background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/企业服务-背景.png);
}

//   上方海报
.poster {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 620px;
    background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/企业服务banner2.png);
    background-repeat: no-repeat;
    background-size: 100% 160%;

    .introduce {
        width: 800px;
        text-align: left;
        margin-left: 180px;
        box-sizing: border-box;

        h2 {
            font-size: 60px;
            font-family: AliHYAiHei;
            font-weight: 400;
            color: #F4FBFB;
            line-height: 69px;
        }

        p {
            box-sizing: border-box;
            font-size: 22px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #F7FBFA;
            line-height: 33px;
            margin: 40px 0;
        }

        .el-button {
            width: 224px;
            height: 58px;
            background: transparent;
            // box-shadow: 2px 2px 12px 6px rgba(2, 114, 230, 0.23);
            font-size: 24px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 31px;
            border: 1px solid #FFFFFF;
            border-radius: 12px;
        }
    }
}

.scheme {
    margin-top: 80px;
    color: #111;
    .schemeMain {
        width: 1400px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        .schemeMainbox{
            width: 25%;
            padding: 20px 50px 30px;
            border: 1px solid #e1e1e1;
            h3{
                line-height: 50px;
                font-weight: 600;
                font-size: 18px;
            }
            p{
                font-size: 16px;
                line-height: 30px;
            }
        }
        img {
            width: 100%;
            height: 100%;
            margin-bottom: 50px;
        }
    }
}
.CNtitle{
    color: #111;
}
</style>
