<template>
<div>
    <Nav />
    <!-- 上方海报 -->
    <div class="poster">
        <div class="introduce">
            <h2>能源解决方案</h2>
            <p>提供能源节能降耗解决方案，针对多应用场景，提供综合能源和碳计量服务，基于
                AI数据分析、物联网、大数据及行业应用能力，为政府和企业实现精细化能源管理，
                增强能源降耗、提高运维效率。</p>
            <el-button type="" @click="consult">立即咨询</el-button>
        </div>
    </div>

    <!-- 方案优势 -->
    <div class="advantage">
        <h2 class="CNtitle">方案优势</h2>
        <div>
            <div>
                <h3>提高能源管控能力，降低能耗</h3>
                <p>基于信息化、物联网、人工智能等技术实现能源消耗实时监控预警，协助企业实现数字化管理。</p>
            </div>
            <div>
                <h3>态势感知大脑，辅助决策</h3>
                <p>利用大数据可视化、物联网、人工智能等技术，实现能源和设备的全面感知，提高企业管理能力，提高能源利用效益。</p>
            </div>
            <div>
                <h3>为企业提供一站式解决方案</h3>
                <p>为能源行业提供多场景解决方案，覆盖设备接入、设备监控、能源监视、能效分析、能效优化、能源管理等功能交付及售后服务。</p>
            </div>
        </div>
    </div>

    <!-- 核心能力 -->
    <div class="core">
        <h2 class="CNtitle">核心能力</h2>
        <div>
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/能源-核心能力.png" alt="">
        </div>
    </div>

    <!-- <Side /> -->

    <Footer ref="Footer" />
</div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Nav from "@/components/nav.vue";
import Side from "@/components/side.vue";
export default {
    components: {
        Footer,
        Nav,
        Side
    },
    methods: {
        consult() {
            this.$refs.Footer.checkShow(2)
        }
    }
}
</script>

<style lang="scss" scoped>
//   上方海报
.poster {
    width: 100%;
    height: 620px;
    background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/能源海报.png);
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .introduce {
        width: 800px;
        text-align: left;
        margin-left: 180px;
        box-sizing: border-box;

        h2 {
            font-size: 60px;
            font-weight: 400;
            color: #fff;
            line-height: 69px;
        }

        p {
            box-sizing: border-box;
            font-size: 22px;
            font-weight: 400;
            color: #fff;
            line-height: 33px;
            margin: 40px 0;
        }

        .el-button {
            width: 224px;
            height: 58px;
            background: #fb5604;
            border-radius: 8px;
            border: none;
            font-size: 24px;
            font-weight: 500;
            color: #ffffff;
            line-height: 31px;
        }
    }
}

// 方案优势
.advantage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;

    >div {
        display: flex;
        justify-content: space-between;
        width: 90%;

        >div {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 520px;
            height: 280px;
            border: 1px solid #DADADA;
            padding: 20px 20px 50px 20px;
            box-sizing: border-box;

            h3 {
                font-size: 24px;
                font-weight: 500;
                color: #202020;
                line-height: 48px;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                color: #717171;
                line-height: 31px;
                text-align: left;
            }
        }
    }
}

// 核心能力
.core {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px 0;

    >div {
        width: 90%;

        img {
            width: 100%;
        }
    }
}
</style>
