<template>
<div class="smartTraffic">
    <Nav />
    <!-- 上方海报 -->
    <div class="poster">
        <div class="introduce">
            <h2>智慧交通</h2>
            <p>运用物联网效能、云计算、人工智能、自动控制、移动互联网等技术，对信号机进行联合控制，实现对路口信号控制的全局掌控，在区域、城市甚至更大的时空范围具备感知、互联、分析、预测、控制等能力，以充分保障交通安全、发挥交通基础设施效能、提升交通系统运行效率和管理水平，为通畅的公众出行和可持续的经济发展服务。</p>
            <el-button type="" @click="consult">立即咨询</el-button>
        </div>
    </div>
    <!-- 产品作用 -->
    <h6>行业痛点</h6>
    <div class="ProductFunction1">
        <div class="funItem">
            <h2>交通拥堵时间持续增加</h2>
            <p>
                在道路供给总量明显赶不上机动车增长情况下，交通拥堵时间持续增加，交通拥堵空间持续扩散，迫切需要开展智能化交通信号控制工作。
            </p>
        </div>
        <div class="funItem">
            <h2>传统交通监控方法效率低</h2>
            <p>
                依靠传统交通监控方法大量依赖人工，效率不高，无法解决问题。
            </p>
        </div>
        <div class="funItem">
            <h2>交通管理面临调整</h2>
            <p>
                交通流量与日俱增，道路拥堵、人车冲突、硬件设施管理不善等问题凸显，也逐渐产生一系列问题。
            </p>
        </div>
    </div>
    <div class="trafficScheme">
        <h2 class="title">智慧交通解决方案</h2>
        <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/smartTraffic.png" alt="">

        <!-- 平台优势0001  -->
        <div class="PlatformAdvantages">
            <h2 class="title">平台优势</h2>
            <div class="ProductFunction">
                <div class="funItem mb20">
                    <div>
                        <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/调度.png" alt="">
                        <h3>指挥调度中心</h3>
                    </div>
                    <p>
                        基于交通大数据平台将路口数据、路口视频、设备数据、事件数据、警力数据、路况数据等通过大屏可视化方式呈现，实现交通实况、交通事件实时监控、警力资源实时调度指挥，辅助管理人员决策与调度。
                    </p>
                </div>
                <div class="funItem mb20">
                    <div>
                        <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/设备管理.png" alt="">
                        <h3>设备管理</h3>
                    </div>
                    <p>
                        基于物联网技术，将路口信号机、路口设备等设备进行管理，实现交通管理的基础建设及对路口、控制区的规划。
                    </p>
                </div>
                <div class="funItem mb20">
                    <div>
                        <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/交通控制.png" alt="">
                        <h3>交通控制</h3>
                    </div>
                    <p>
                        基于物联网技术，将路口信号机、路口设备等设备进行管理与控制。
                    </p>
                </div>
                <div class="funItem mb20">
                    <div>
                        <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/交通态势.png" alt="">
                        <h3>交通态势感知</h3>
                    </div>
                    <p>
                        基于交通大数据平台支持从全局道路交通信号状态显示，对路口，干线以及区域交通的安全态势的展示，实现交通安全态势分析。
                    </p>
                </div>
                <div class="funItem mb20">
                    <div>
                        <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/设施管理.png" alt="">
                        <h3>设施管理</h3>
                    </div>
                    <p>
                        基于物联网技术，将路口的T杆、F杆、防撞桶、减速带、反光锥等设施进行管理记录，实现交通指挥管理系统的完整性
                    </p>
                </div>
                <div class="funItem mb20">
                    <div>
                        <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/事件管理.png" alt="">
                        <h3>事件管理</h3>
                    </div>
                    <p>
                        基于GIS技术，将警务人员上报的事件进行管理，对事件进行告警、分派、事件处置归档等处理，实现及时采取恰当的应对措施，处置事件归档，快速降低或消除事件带来的影响,从而提高交通系统的运行效率和安全性。
                    </p>
                </div>
                <div class="funItem mb20">
                    <div>
                        <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/方案库.png" alt="">
                        <h3>方案预案</h3>
                    </div>
                    <p>
                        通过对信号机控制机、特勤配置预案、重大节日预案、事件处置预案等功能，实现交通控制设置的复用及特殊日期的交通控制、发生交通事件后的预案参考。
                    </p>
                </div>
                <div class="funItem mb20">
                    <div>
                        <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/平台管理.png" alt="">
                        <h3>平台管理</h3>
                    </div>
                    <p>
                        系统使用人员管理、部门管理、角色管理、系统日志管理，作为平台使用的基础建设。
                    </p>
                </div>
                <div class="funItem mb20">
                    <div>
                        <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/交通大数据.png" alt="">
                        <h3>交通大数据平台</h3>
                    </div>
                    <p>
                        基于大数据技术，实现对交通数据的采集、融合、计算、分析、实现城市交通数据整合，助力各类交通业务创新发展。
                    </p>
                </div>
            </div>
        </div>
        <!-- 平台优势  -->
        <div class="PlatformAdvantages">
            <h2 class="title">平台优势</h2>
            <div class="ProductFunction">
                <div class="funItem">
                    <h3>科学智能决策</h3>
                    <p>
                        通过大数据分析、AI算法为智慧公安提供科学预测、控制、分析决策，实现警务工作全生命周期管控。
                    </p>
                </div>
                <div class="funItem">
                    <h3>全周期智能化服务体系</h3>
                    <p>
                        提供全生命周期的服务体系，为交通领域量身定制从分析、规划、设计、研发、售后等特色服务及解决方案。
                    </p>
                </div>
                <div class="funItem">
                    <h3>高新技术应用能力</h3>
                    <p>
                        智慧交通采用大数据、物联网、AI算法等核心技术为智慧交通赋能。
                    </p>
                </div>
            </div>
        </div>
        <!-- 客户案例 -->
        <!-- <div class="customerCase">
            <h2 class="title">客户案例</h2>
            <div class="caseMain">
                <p>情指一体化</p>
            </div>
        </div> -->
    </div>

    <!-- <Side /> -->

    <Footer ref="Footer" />
</div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Nav from "@/components/nav.vue";
import ProTop from "../../components/productService/proTop.vue";
import Side from "@/components/side.vue";
export default {
    components: {
        Footer,
        Nav,
        ProTop,
        Side
    },
    methods: {
        consult() {
            this.$refs.Footer.checkShow(2)
        }
    }
};
</script>

<style lang="scss" scoped>
//   上方海报
.poster {
    width: 100%;
    height: 620px;
    background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/home_swiper4.png);
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .introduce {
        width: 800px;
        text-align: left;
        margin-left: 180px;
        box-sizing: border-box;

        h2 {
            font-size: 60px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            line-height: 69px;
        }

        p {
            box-sizing: border-box;
            font-size: 22px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #fff;
            line-height: 33px;
            margin: 40px 0;
        }

        .el-button {
            width: 224px;
            height: 58px;
            background: #FB5604;
            border-radius: 8px;
            border: none;
            font-size: 24px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 31px;
        }
    }
}

// 行业痛点
h6 {
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #202020;
    line-height: 30px;
    margin: 80px 0 50px 0;
}

.ProductFunction1 {
    width: 80%;
    display: flex;
    justify-content: space-between;

    .funItem {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 480px;
        height: 280px;
        border: 1px solid #dadada;
        box-sizing: border-box;
        padding: 20px 35px;

        h2 {
            font-size: 24px;
            font-weight: 500;
            color: #202020;
            line-height: 48px;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            color: #717171;
            line-height: 31px;
            text-align: left;
        }
    }
}

.smartTraffic {
    display: flex;
    flex-direction: column;
    align-items: center;

    .trafficScheme {
        width: 80%;

        img {
            width: 100%;
            height: 100%;
        }

        // 平台优势
        .PlatformAdvantages {
            margin-bottom: 80px;
            .ProductFunction {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .funItem {
                    display: flex;
                    flex-direction: column;
                    // justify-content: space-around;
                    align-items: center;
                    width: 480px;
                    height: 280px;
                    border: 1px solid #dadada;
                    box-sizing: border-box;
                    padding: 20px 35px;

                    img {
                        width: 50px;
                        height: 50px;
                    }

                    h3 {
                        font-size: 24px;
                        font-weight: 500;
                        color: #202020;
                        line-height: 48px;
                    }

                    p {
                        font-size: 16px;
                        font-weight: 400;
                        color: #717171;
                        line-height: 31px;
                        text-align: left;
                    }
                }
            }
        }

        // 客户案例
        .customerCase {
            height: 350px;
            margin-bottom: 80px;

            .caseMain {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                height: 300px;
                background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/integrated.png);
                background-repeat: no-repeat;
                background-size: 100% 100%;

                p {
                    height: 60px;
                    font-size: 30px;
                    font-weight: 500;
                    color: #ffffff;
                    background-color: rgba($color: #000000, $alpha: 0.5);
                    line-height: 60px;
                }
            }
        }
    }
}
</style>
