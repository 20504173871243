<template>
<div>
    <div class="formwork">
        <Nav />
        <!-- 上方海报 -->
        <div class="poster bg3">
            <div class="back" @click="back">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/返回.png" alt="">
                <h2>
                    返回案例
                </h2>
            </div>
            <el-button  @click="consult">立即咨询</el-button>
        </div>
        <div class="main">
            <div class="bground">
                <h2>项目背景</h2>
                <p> 本项目是蓉江新区建设智慧城市工程之中的顶层设计，建设赣州蓉江新区智慧城市建设项目城市中台专项，全面推进新区数据及智慧业务发展和应用，促进数据产业发展，加快智慧城市建设。</p>
            </div>
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <div class="painPoint">
                <h2>项目痛点</h2>
                <p> 纯纸质业务流程，无法与系统流程相对应结合起来办理业务。
                    <br>
                    目前单位之间的沟通或者部门之间办理业务，都是通过邮件和纸质文件定期汇报等方式进行。信息流通的时效性差，灵活性差，时间浪费明显。
                    <br>
                    现在单位的业务重复性较高，很多工作重复开发，各部门协调困难。</p>
            </div>
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <div class="solve">
                <h2>解决方案</h2>
                <p> 业务中台作为的基础性支撑平台，主要定位为各类信息化应用提供功能完整、性能优良、可靠性高的业务、技术公共组件，解决应用系统建设中的共性问题。含有各个组件中心：统一用户中心、统一服务中心、统一知识库中心、统一事件中心、统一编码中心等</p>
                <div class="list">
                    <div>
                        <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/点.png" alt="">
                        <h3>业务服务支撑平台</h3>
                    </div>
                    <ul>
                        <li>通过用户端、服务端、运营端、服务门户、网管服务为蓉江新区各部门信息资源建立一个统一服务支撑平台，进行数据汇聚，服务共享，打通“数据孤岛，推动政府信息资源互联互通、有效开发和深度利用。</li>
                    </ul>
                    <div style="margin-top: 20px">
                        <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/点.png" alt="">
                        <h3>业务服务能力</h3>
                    </div>
                    <ul>
                        <li>统一用户中心：一款集用户认证、组织管理、用户管理、单点登录功能为一体的安全便捷的数字化服务。实现用户可使用一个账号登录所有应用服务，随时随地安全接入。提升企业的高效管理、加强系统的安全保护、优化用户的操作体验。</li>
                        <li>城市编码中心：一款设备信息编码工具，作为设备信息化建设中的基础服务。通过建立设备建立统一编码，实现编码的一致性、完整性、易用性、可追溯性与信息交互和数据共享能力。</li>
                        <li>城市事件中心：城市事件服务通过数据采集与事件标准化、流程化将散落在各个系统中的城市事件打通，基于统一集中管理事件流程及流程模版、应用配置、事件分类等功能，打通系统间的壁垒，标准化事件信息，优化事件处理流程，提高事件处理效率，推动城市管理建设走向信息化、标准化、智慧化发展方向。</li>
                        <li>城市知识中心：在智慧城市场景下，所有的知识发布，提供统一的外放途径。只建立一套知识库系统，满足所有知识查询者和使用者的需求。</li>
                        <li>城市AI服务：为智慧城市场景下，通过证件识别、人脸识别等人工智能服务。</li>
                        <li>......</li>
                    </ul>
                </div>
                <img class="computer" src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/智慧城市-业务中台.png" alt="">
            </div>
            <div class="achievement">
                <h2>项目成就</h2>
                <p> 打破信息孤岛，整合城市资源
                    <br>
                    智慧城市投入运营后，先后接入公共信息平台、智慧交通、智慧教育等11个专项应用项目先后上线试运行，打破原有资源的孤岛，整合城市各部门资源，为城市治理数字赋能提供有力支撑。
                    <br>
                    城市治理效能实现质的飞跃
                    <br>
                    道路旁非机动车未有序摆放、小区内垃圾桶未盖盖板、工地上工人未戴安全头盔等不文明不安全行为均会被自动抓取，数据及时反馈至信息平台，从发现问题、上报问题到现场处置问题，由原来的几个小时缩短至10分钟以内</p>
            </div>
        </div>
    </div>

    <!-- <Side /> -->


    <Footer ref="Footer" />
</div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Nav from "@/components/nav.vue";
import Side from "@/components/side.vue";
export default {
    components: {
        Footer,
        Nav,
        Side
    },
    mounted() {
        this.showWhat = sessionStorage.getItem("id")
        window.scrollTo(0, 0)
    },
    data() {
        return {
            showWhat: null
        }
    },
    methods: {
        back() {
            this.$router.push("/case")
        },
        consult() {
            this.$refs.Footer.checkShow(2)
        }
    }
}
</script>

<style lang="scss" scoped>
.formwork {
    display: flex;
    flex-direction: column;
    align-items: center;
}

//   上方海报
.poster {
    position: relative;
    width: 100%;
    height: 620px;
    background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/业务中台banner.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-bottom: 50px;

    .back {
        display: flex;
        align-items: center;
        padding: 20px 0 0 150px;
        cursor: pointer;

        img {
            width: 30px;
            height: 30px;
            font-size: 50px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 55px;
            margin-right: 10px;
        }

        h2 {
            font-size: 29px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 55px;
        }
    }

    .el-button {
        position: absolute;
        top: 500px;
        left: 180px;
        width: 203px;
        height: 50px;
        background: linear-gradient(0deg, #F28E26, #FD644F);
        font-size: 25px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
        border-radius: 40px;
        border: none;
    }
}

.main {
    width: 90%;

    >div {
        text-align: left;
        margin-bottom: 110px;

        h2 {
            padding-right: 50px;
            box-sizing: border-box;
            text-align: center;
            width: 260px;
            height: 80px;
            background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/框.png);
            background-size: 100%;
            background-repeat: no-repeat;
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #202020;
            line-height: 80px;
            margin-bottom: 40px;
        }

        p {
            width: 65%;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 31px;
            padding-left: 50px;
            box-sizing: border-box;
        }
    }

    .list {
        margin-top: 45px;

        div {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            img {
                width: 30px;
                height: 30px;
                margin-right: 10px;
            }

            h3 {
                padding-top: 5px;
                font-size: 21px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #202020;
                line-height: 30px;
                box-sizing: border-box;
            }

        }

        ul {
            padding-left: 60px;

            li {
                width: 50%;
                list-style-type: decimal;
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #666666;
                line-height: 33px;
            }
        }

    }

    .computer {
        width: 530px;
        height: 434px;
        margin: 30px 0 0 60px;
    }
}
</style>
