<template>
<div>
    <Nav />
    <div>

        <!-- 上方海报 -->
        <div class="poster">
            <div class="introduce">
                <el-button type="" @click="consult">立即咨询</el-button>
            </div>
        </div>
    </div>

    <!-- 核心能力 -->
    <div class="core">
        <h2 class="ENtitle">CORE COMPETENCE</h2>
        <h2 class="CNtitle">核心能力</h2>
        <div class="coreMain">
            <div>
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/inter-接入.png" alt="">
                <h3>设备接入</h3>
                <p>IoT设备接入管理系统，满足设备远程可视化管理，实现设备接入、设备认证、设备协议解析等功能。</p>
            </div>
            <div>
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/inter-管理.png" alt="">
                <h3>设备管理</h3>
                <p>支持设备统一管理，设备全生命周期管理、设备业务控制、数字数据可视化，全面提升企业对设备等管控能力。</p>
            </div>
            <div>
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/inter-运维.png" alt="">
                <h3>监控运维</h3>
                <p>实现设备日志全链路检索,设备实时监控高级远程调试等运维能力。</p>
            </div>
            <div>
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/inter-分析.png" alt="">
                <h3>数据分析</h3>
                <p>实现对设备数据的同步备份能力，基于数据的可视化分析，提升企业对设备的实时监控及掌控能力。</p>
            </div>
        </div>
    </div>

    <!-- 应用领域 -->
    <div class="application">
        <h2 class="ENtitle">APPLICATION AREA</h2>
        <h2 class="CNtitle">应用领域</h2>
        <div class="appMain">
            <div class="arrow" @click="areaSub()">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/left.png" alt="">
            </div>
            <div :class="[0 === activeArea ? 'bgShow areaItem' : 'areaItem']">
                <h3>工业物联网</h3>
                <p>将具有感知、监控能力的各类采集、控制传感器或控制器，以及移动通信、智能分析等技术不断融入到工业生产过程各个环节，从而大幅提高制造效率，改善产品质量，降低产品成本和资源消耗，实现智能化的新阶段。
                </p>
            </div>
            <div :class="[1 === activeArea ? 'bgShow areaItem' : 'areaItem']">
                <h3>仓储物流</h3>
                <p>采用基于LoRa、NB- lo T等传输网络的物联网技术完成仓库入口、盘点、调拨、出库等仓储场景。通过车辆定位检测物流行驶期刊、车辆油耗、车速等场景。

                </p>
            </div>
            <div :class="[2 === activeArea ? 'bgShow areaItem' : 'areaItem']">
                <h3>智慧食堂</h3>
                <p>基于物联网技术结合食堂支付场景，接入支付设备，采集订单数据，实现食堂支付场景的智能化。
                </p>
            </div>
            <div :class="[3 === activeArea ? 'bgShow areaItem' : 'areaItem']">
                <h3>智慧社区</h3>
                <p>通过物联网技术,把原本分散独立的社区智能安防、智能家居、智慧物业服务等集成在智慧社区管理系统中,有利于增进物业公司与业主的沟通，建立智慧社区服务标准，提高智慧社区实际应用水平。
                </p>
            </div>
            <div class="arrow" @click="areaAdd()">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/right.png" alt="">
            </div>
        </div>
    </div>

    <!-- 方案优势 -->
    <div class="advantage">
        <h2 class="ENtitle">ADVANTAGE</h2>
        <h2 class="CNtitle">方案优势</h2>
        <div class="adMain">
            <div class="adItem1 adItem">
                <div class="default">
                    <h3>应用场景丰富</h3>
                    <p>可应用场景丰富，满足各类设备和接入场景的定制化需求。
                    </p>
                </div>
                <div class="active">
                    <h3>应用场景丰富</h3>
                    <p>可应用场景丰富，满足各类设备和接入场景的定制化需求。
                    </p>
                    <!-- <el-button round>了解详情</el-button> -->
                </div>
            </div>
            <div class="adItem2 adItem">
                <div class="default">
                    <h3>全面的服务体系</h3>
                    <p>提供全生命周期的服务体系，为饥客户量身定制从分析、规划、设计、研发、售后等特色服务及解决方案
                    </p>
                </div>
                <div class="active">
                    <h3>全面的服务体系</h3>
                    <p>提供全生命周期的服务体系，为饥客户量身定制从分析、规划、设计、研发、售后等特色服务及解决方案
                    </p>
                    <!-- <el-button round>了解详情</el-button> -->
                </div>
            </div>
            <div class="adItem3 adItem">
                <div class="default">
                    <h3>安全可靠</h3>
                    <p>专业物联网通道，数据加密传输，全方位保障企业的数据安全及设备安全。
                    </p>
                </div>
                <div class="active">
                    <h3>安全可靠</h3>
                    <p>专业物联网通道，数据加密传输，全方位保障企业的数据安全及设备安全。
                    </p>
                    <!-- <el-button round>了解详情</el-button> -->
                </div>
            </div>
        </div>
    </div>

    <!-- 客户案列 -->
    <div class="case">
        <h2 class="ENtitle">CUSTOMER CASE</h2>
        <h2 class="CNtitle">客户案列</h2>
        <div class="caseMain">
            <a href="../safeCommunity">
                <div class="MainL">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/inter-案例1.png" alt="">
                    <h3>智慧社区</h3>
                </div>
            </a>
            <div class="MainR">
                <a href="../smartTraffic">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/inter-案例2.png" alt="">
                    <h3>智慧交通</h3>
                </a>
                <a href="../integrated">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/inter-案例3.png" alt="">
                    <h3>情指化一体</h3>
                </a>
            </div>
        </div>
    </div>

    <!-- <Side /> -->

    <Footer ref="Footer" />
</div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Nav from "@/components/nav.vue";
import Side from "@/components/side.vue";
export default {
    components: {
        Footer,
        Nav,
        Side
    },
    data() {
        return {
            activeArea: 0,
        };
    },
    methods: {
        areaSub() {
            if (this.activeArea <= 0) {
                this.activeArea = 3;
            } else {
                this.activeArea--;
            }
        },
        areaAdd() {
            if (this.activeArea >= 3) {
                this.activeArea = 0;
            } else {
                this.activeArea++;
            }
        },
        consult() {
            this.$refs.Footer.checkShow(2)
        }
    },
};
</script>

<style lang="scss" scoped>
//   上方海报
.poster {
    width: 100%;
    height: 620px;
    background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/物联网banner.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .introduce {
        width: 800px;
        text-align: left;
        margin-left: 160px;
        box-sizing: border-box;

        .el-button {
            margin-top: 280px;
            width: 224px;
            height: 58px;
            background: #f28e26;
            box-shadow: 2px 2px 12px 6px rgba(2, 114, 230, 0.73);
            border-radius: 8px;
            border: none;
            font-size: 24px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 31px;
        }
    }
}

// 核心能力
.core {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 75px;

    .coreMain {
        display: flex;
        justify-content: space-between;
        width: 90%;

        div {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 320px;
            height: 400px;

            img {
                width: 160px;
                height: 110px;
            }

            h3 {
                margin: 40px 0 30px 0;
                font-size: 24px;
                font-weight: 500;
                color: #202020;
                line-height: 48px;
            }

            p {
                text-align: left;
                font-size: 16px;
                font-weight: 400;
                color: #717171;
                line-height: 31px;
            }
        }
    }
}

// 应用领域
.application {

    .appMain {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 75px;
            height: 60px;
            line-height: 60px;
            color: #fb5604;
            font-size: 30px;
            cursor: pointer;

            img {
                width: 30px;
            }
        }

        .arrow:hover {
            color: #fff;
        }

        >div:first-child {
            border-radius: 38px 0 0 38px;
        }

        >div:first-child:hover {
            // background: linear-gradient(-270deg, #fd644f, #f28e26);
            background: #ccc;
        }

        >div:last-child {
            border-radius: 0px 38px 38px 0;
        }

        >div:last-child:hover {
            // background: linear-gradient(-270deg, #f28e26, #fd644f);
            background: #ccc;
        }

        .areaItem {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 350px;
            height: 430px;
            box-shadow: 0px 8px 9px 1px rgba(153, 153, 153, 0.3);
            padding: 0 30px 60px 30px;
            box-sizing: border-box;

            h3 {
                font-size: 24px;
                font-weight: 500;
                color: #202020;
                line-height: 48px;
                margin: 60px 0;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                color: #717171;
                line-height: 31px;
                text-align: left;
            }
        }

        .bgShow {
            width: 380px;
            height: 460px;
            background: linear-gradient(0deg, rgba(164, 222, 224, 0), rgba(3, 174, 217, 0.2));
            box-shadow: 0px 8px 9px 1px rgba(29, 246, 253, 0.3);
        }
    }
}

// 方案优势
.advantage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;

    .adMain {
        display: flex;
        justify-content: space-between;
        width: 90%;

        .adItem1 {
            background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/inter-场景.png);
        }

        .adItem2 {
            background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/inter-体系.png);
        }

        .adItem3 {
            background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/inter-安全.png);
        }

        .adItem {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 500px;
            height: 300px;
            padding: 50px 20px 0 20px;
            box-sizing: border-box;
            background-repeat: no-repeat;
            background-size: 110% 110%;

            .default {
                display: block;

                h3 {
                    font-size: 24px;
                    font-weight: 400;
                    color: #202020;
                    line-height: 30px;
                    margin-bottom: 40px;
                }

                p {
                    width: 163px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #717171;
                    line-height: 30px;
                    text-align: left;
                }
            }

            .active {
                display: none;
                color: #fff;

                h3 {
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 30px;
                    margin-bottom: 50px;
                }

                p {
                    width: 100%;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 30px;
                    margin-bottom: 50px;
                }

                .el-button {
                    border: 2px solid #FFFFFF;
                    background-color: transparent;
                    color: #fff;
                }
            }
        }

        .adItem:hover .default {
            display: none;
        }

        .adItem:hover .active {
            display: block;
        }

        .adItem:hover {
            align-items: center;
            background: linear-gradient(119deg, #0A6BFE, #5DADFD);
        }
    }
}

// 客户案列
.case {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px 0;

    .caseMain {
        display: flex;
        justify-content: space-around;
        width: 90%;
        margin-top: 10px;

        h3 {
            font-size: 24px;
            font-weight: 400;
            color: #202020;
            line-height: 60px;
        }

        .MainL {

            img {
                width: 775px;
                height: 525px;
            }
        }

        .MainR {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            img {
                width: 774px;
                height: 225px;
            }
        }
    }
}
</style>
