<template>
<div class="proTop">
    <!-- 产品介绍 -->
    <div class="ProductIntroduction">
        <h2>什么是智慧城市？</h2>
        <p>
            智慧城市利用信息通信技术(ICT)提高运营效率，与公众共享信息，并提供更优质的政府服务和公民福利。智慧城市的主要目标是优化城市功能，促进经济增长，同时通过智能技术和数据分析提高市民的生活质量。
        </p>
    </div>
    <!-- 产品作用 -->
    <div class="ProductFunction">
        <div class="funItem">
            <h2>聚焦基础建设，推进政务云体系</h2>
            <p>
                构建完善电子政务外网政务云、行业云建设应用，完成政务云体系。全面推进新区数据及智慧业务开发和应用，促进数据产业发展，加快智慧城市建设。
            </p>
        </div>
        <div class="funItem">
            <h2>聚焦数据共享，促进数据资源整合</h2>
            <p>
                构建统一大数据基础服务能，优化完成数据资源采集、传输、存储、计算、处理、确权、使用等环节运维机制，实现统一数据治理，提升数据共享治理，加强数据赋能开放，打破数据壁垒。
            </p>
        </div>
        <div class="funItem">
            <h2>聚焦城市管理，推动重点领域建设</h2>
            <p>
                构建统一城市治理、公共安全、城市服务、信用信息等智慧城市应用体系，提升城市管理与服务水平、促进产业升级、提高市民生活水平。
            </p>
        </div>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.proTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;



    // 产品介绍
    .ProductIntroduction {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 80px 0 60px 0;

        h2 {
            font-size: 30px;
            font-weight: 500;
            color: #202020;
            margin-bottom: 20px;
        }

        p {
            width: 890px;
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            color: #202020;
            line-height: 30px;
        }
    }

    // 产品作用
    .ProductFunction {
        width: 80%;
        display: flex;
        justify-content: space-between;

        .funItem {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            width: 480px;
            height: 280px;
            border: 1px solid #dadada;
            box-sizing: border-box;
            padding: 20px 35px;

            h2 {
                font-size: 24px;
                font-weight: 500;
                color: #202020;
                line-height: 48px;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                color: #717171;
                line-height: 31px;
                text-align: left;
            }
        }
    }
}
</style>
