<template>
<div class="AI">
    <Nav />

    <!-- 上方海报 -->
    <div class="poster">
        <div class="introduce">
            <h2>人工智能解决方案</h2>
            <p>提提供集成人工智能服务解决方案，提高政府与企业的能力和创造力。</p>
            <el-button type="" @click="consult">立即咨询</el-button>
        </div>
    </div>

    <!-- 核心技术 -->
    <div class="coreTechnology">
        <h2 class="ENtitle">CORE TECHNOLOGY</h2>
        <h2 class="CNtitle">核心技术</h2>
        <div class="technologyMain">
            <div class="technologyItem">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/ai-数据看板.png" alt="" />
                <h2>数据挖掘与分析</h2>
                <p>
                    面临大数据深度挖掘与分析时，通常采用机器学习，是基于人工神经网络的深度学习可针对海量数据进行分析计算，并创建相应模型。
                </p>
            </div>
            <div class="technologyItem">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/ai-数据挖掘.png" alt="" />
                <h2>知识和数据智能处理</h2>
                <p>
                    知识处理时通常使用专家技术，它能运用特定领域中专家提供的专门知识和经验，通过智能推理求和，便可解决只有专家才能解决的问题。
                </p>
            </div>
            <div class="technologyItem">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/ai-人机交互.png" alt="" />
                <h2>人机交互</h2>
                <p>
                    主要运用到的技术包括机器人学和模式识别技术，机器人可以模拟人的行为，而模式识别则能使用计算机模拟人类器官对外界的各种感知。
                </p>
            </div>
        </div>
    </div>

    <div class="main">
        <!-- 应用领域 -->
        <h2 class="ENtitle">APPLICATION AREA</h2>
        <h2 class="CNtitle">应用领域</h2>
        <div class="applicationArea">
            <!-- <SwiperAI /> -->
            <div class="arrow" @click="areaSub()">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/left.png" alt="">
            </div>
            <div :class="[0 === activeArea ? 'bgShow areaItem' : 'areaItem']">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/ai-智慧交通.png" alt="" />
                <p>AI+智慧交通</p>
            </div>
            <div :class="[1 === activeArea ? 'bgShow areaItem' : 'areaItem']">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/ai-中台.png" alt="" />
                <p>AI中台
                </p>
            </div>
            <div :class="[2 === activeArea ? 'bgShow areaItem' : 'areaItem']">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/ai-智慧食堂.png" alt="" />
                <p>AI+智慧食堂</p>
            </div>
            <div :class="[3 === activeArea ? 'bgShow areaItem' : 'areaItem']">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/ai-智慧医疗.png" alt="" />
                <p>AI+智慧医疗</p>
            </div>
            <div :class="[4 === activeArea ? 'bgShow areaItem' : 'areaItem']">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/ai-智慧制造.png" alt="" />
                <p>AI+智慧制造</p>
            </div>
            <div :class="[5 === activeArea ? 'bgShow areaItem' : 'areaItem']">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/ai-智慧政务.png" alt="" />
                <p>AI+智慧政务</p>
            </div>
            <div :class="[6 === activeArea ? 'bgShow areaItem' : 'areaItem']">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/ai-智慧教育.png" alt="" />
                <p>AI+智慧教育</p>
            </div>
            <div class="arrow" @click="areaAdd()">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/right.png" alt="">
            </div>
        </div>

        <!-- 方案优势 -->
        <div class="fangan">
            <h2 class="ENtitle">ADVANTAGE</h2>
            <h2 class="CNtitle" style="color:#fff">方案优势</h2>
            <div class="advantage">
                <div class="advItem">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/人工智能-方案优势1.png" alt="" />
                    <div>
                        <h3>应用场景丰富</h3>
                        <p>可应用场景丰富，满足各类场景的定制化需求。</p>
                    </div>
                </div>
                <div class="advItem">
                    <img class="secImg" src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/人工智能-方案优势2.png" alt="" />
                    <div>
                        <h3>全面的服务体系</h3>
                        <p>
                            提供全生命周期的服务体系，为客户量身定制从分析、规划、设计、研发、售后等特色服务及解决方案。
                        </p>
                    </div>
                </div>
                <div class="advItem">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/人工智能-方案优势3.png" alt="" />
                    <div>
                        <h3>成熟的项目落地方案</h3>
                        <p>
                            已为上百家客户提供专业解决方案，遵循全球公认的ISO质量体系，精细打磨每-个细节。
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- 客户案列 -->
        <h2 class="ENtitle">CUSTOMER CASE</h2>
        <h2 class="CNtitle">客户案列</h2>
        <div class="case">
            <div>
                <a href="smartTraffic">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/智慧交通.png" alt="" />
                    <p>智慧交通</p>
                </a>
            </div>
            <div>
                <a href="businessCenter">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/业务中台.png" alt="" />
                    <p>业务中台</p>
                </a>
            </div>
        </div>
    </div>

    <!-- <Side /> -->

    <Footer ref="Footer" />
</div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Nav from "@/components/nav.vue";
import Side from "@/components/side.vue";
import SwiperAI from "@/components/swiperAI.vue";
export default {
    components: {
        Footer,
        Nav,
        Side,
        SwiperAI
    },
    data() {
        return {
            activeArea: 0,
        };
    },
    methods: {
        areaSub() {
            if (this.activeArea <= 0) {
                this.activeArea = 6;
            } else {
                this.activeArea--;
            }
        },
        areaAdd() {
            if (this.activeArea >= 6) {
                this.activeArea = 0;
            } else {
                this.activeArea++;
            }
        },
        consult() {
            this.$refs.Footer.checkShow(2)
        }
    },
};
</script>

<style lang="scss" scoped>
.AI .poster .introduce .el-button {
    background: #00c9f4 !important;
    border-radius: 23px !important;
}

.AI {
    display: flex;
    flex-direction: column;
    align-items: center;
    // background-color: #f1f6ff;

    //   上方海报
    .poster {
        width: 100%;
        height: 620px;
        background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/人工智能-banner.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;

        .introduce {
            width: 800px;
            text-align: left;
            margin-left: 180px;
            box-sizing: border-box;

            h2 {
                font-size: 60px;
                font-weight: 400;
                color: #fff;
                line-height: 69px;
            }

            p {
                box-sizing: border-box;
                font-size: 22px;
                font-weight: 400;
                color: #fff;
                line-height: 33px;
                margin: 40px 0;
            }

            .el-button {
                width: 224px;
                height: 58px;
                background: #fb5604;
                border-radius: 8px;
                border: none;
                font-size: 24px;
                font-weight: 500;
                color: #ffffff;
                line-height: 31px;
                // box-sizing: border-box;
            }
        }
    }

    // 核心技术
    .coreTechnology {
        width: 80%;
        margin: 80px 0 75px 0;
        box-sizing: border-box;

        .technologyMain {
            display: flex;
            justify-content: space-around;

            .technologyItem {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                width: 400px;
                height: 420px;
                background: #ffffff;
                box-sizing: border-box;
                padding: 40px 65px;
                box-shadow: 0px 8px 9px 1px rgba(153, 153, 153, 0.3);

                img {
                    width: 100px;
                    height: 100px;
                }

                i {
                    width: 125px;
                    height: 125px;
                    background: linear-gradient(-30deg, #09bbfe, #5a42ec);
                    border-radius: 50%;
                    line-height: 125px;
                }

                h2 {
                    font-size: 24px;
                    font-weight: 500;
                    color: #202020;
                    line-height: 48px;
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    color: #717171;
                    line-height: 31px;
                    text-align: left;
                }
            }
        }
    }

    .main {
        width: 90%;
        background-color: #fff;
        padding-top: 75px;
        box-sizing: border-box;

        // 应用领域
        .applicationArea {
            height: 500px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-bottom: 20px;

            .arrow {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 33px;
                height: 33px;
                color: #fb5604;
                font-size: 30px;
                cursor: pointer;

                img {
                    width: 30px;
                }
            }

            .areaItem {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;

                img {
                    width: 110px;
                    height: 110px;
                }

                p {
                    font-size: 24px;
                    font-weight: 500;
                    color: #202020;
                    line-height: 48px;
                }
            }

            .bgShow {
                width: 221px;
                height: 238px;
                background: #f1f7fe;
                box-shadow: 0px 4px 6px 0px rgba(204, 204, 204, 0.92);
            }
        }

        // 方案优势
        .fangan{
            background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/人工智能-方案优势-背景.png);
            background-repeat: no-repeat;
            background-size: 100%;
            padding: 40px;
            margin-bottom: 40px;
        }
        .advantage {
            display: flex;
            justify-content: space-between;

            .advItem {
                width: 25%;
                background: #ffffff;
                border: 1px solid #000;
                padding: 40px;
                
                img {
                    width: 60px;
                    height: 60px;
                }

                .secImg {
                    width: 70px;
                }

                h3 {
                    text-align: center;
                    font-size: 24px;
                    font-weight: 500;
                    color: #202020;
                    line-height: 48px;
                    margin-bottom: 20px;
                }

                p {
                    text-align: left;
                    font-size: 16px;
                    font-weight: 400;
                    color: #717171;
                    line-height: 31px;
                }
            }
        }

        // 客户案例
        .case {
            display: flex;
            justify-content: space-around;
            margin-bottom: 40px;
            box-sizing: border-box;

            img {
                width: 790px;
                height: 540px;
            }

            p {
                font-size: 24px;
                font-weight: 400;
                color: #202020;
                line-height: 60px;
            }
        }
    }
}
</style>
