<template>
<div class="case">
    <Nav />

    <!-- 上方海报 -->
    <div class="poster">
        <div class="copywriting">
            <h3>值得信赖的软硬件服务企业</h3>
            <p>专业优秀的技术团队，用心服务每一位客F用技术推动想照进现实</p>
        </div>
    </div>

    <div class="main">
        <div class="mainItem">
            <h2>宏观经济库</h2>
            <p>以宏观经济数据为主体，通过大数据技术，以各级各类普查资料、政府综合统计资料、部门行业登记资料、社会经济调查资料等为基础。</p>
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/宏观经济库.png" alt="">
            <el-button type="" @click="toSubpage(1)">了解详情</el-button>
        </div>
        <div class="mainItem">
            <h2>数据中台</h2>
            <p>融合大数据、实时计算等技术，可从指定数据源采集对应数据进行清洗、加工等处理，构建数据中心及应用分析。
            </p>
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/数据中台.png" alt="">
            <el-button type="" @click="toSubpage(2)">了解详情</el-button>
        </div>
        <div class="mainItem">
            <h2>智慧城市-业务中台
            </h2>
            <p>业务中台作为的基础性支撑平台，主要定位为各类信息化应用提供功能完整、性能优良、可靠性高的业务、技术公共组件，解决应用系统建设中的共性问题。
            </p>
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/智慧城市-业务中台.png" alt="">
            <el-button type="" @click="toSubpage(3)">了解详情</el-button>
        </div>
        <div class="mainItem">
            <h2>智慧城市-区块链可信服务
            </h2>
            <p>应用于智慧城市领域的缺乏信任、多方协作、数据资产化、资金监管等等可信服务场景。
            </p>
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/智慧城市-区块链可信服务.png" alt="">
            <el-button type="" @click="toSubpage(4)">了解详情</el-button>
        </div>
        <div class="mainItem">
            <h2>智慧城市-短信网关</h2>
            <p>以业务中台为基础，服务提供了各专项通过短信推动消息的能力，可以定制触达的内容模板，更灵活地通过配置完成信息的发送。</p>
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/智慧城市-短信网关.png" alt="">
            <el-button type="" @click="toSubpage(5)">了解详情</el-button>
        </div>
        <div class="mainItem">
            <h2>智慧平安社区</h2>
            <p>依托互联网+的理念，充分融合智慧社区的各项业务需求，提高社区智能化水平、给居民生活创造便利。</p>
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/智慧平安社区.png" alt="">
            <el-button type="" @click="toSubpage(6)">了解详情</el-button>
        </div>
        <div class="mainItem">
            <h2>情指一体化</h2>
            <p>公安合成作战体系中指挥方面的具体应用平台，是前述信息资源服务和各项应用的落地和实现。
            </p>
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/情指一体化.png" alt="">
            <el-button type="" @click="toSubpage(7)">了解详情</el-button>
        </div>
        <div class="mainItem">
            <h2>协同办公</h2>
            <p>协同办公系统以提高员工生产力，提高内部流程运转效率，提高知识附加率，降低办公用品成本，降低同等任务成本，降低员工流动率提供员工满意度。</p>
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/协同办公.png" alt="">
            <el-button type="" @click="toSubpage(8)">了解详情</el-button>
        </div>
    </div>

    <!-- <Side /> -->

    <Footer />
</div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Nav from "@/components/nav.vue";
import Side from "@/components/side.vue";
import path from "path";
export default {
    components: {
        Footer,
        Nav,
        Side
    },
    methods: {
        toSubpage(num) {
            if (num == 1) {
                this.$router.push("/formwork")
            }
            if (num == 2) {
                this.$router.push("/dataCenter")
            }
            if (num == 3) {
                this.$router.push("/businessCenter")
            }
            if (num == 4) {
                this.$router.push("/trustedService")
            }
            if (num == 5) {
                this.$router.push("/SMSgateway")
            }
            if (num == 6) {
                this.$router.push("/safeCommunity")
            }
            if (num == 7) {
                this.$router.push("/integrated")
            }
            if (num == 8) {
                this.$router.push("/economics")
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.case {
    display: flex;
    flex-direction: column;
    align-items: center;
}

//   上方海报
.poster {
    width: 100%;
    height: 620px;
    background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/客户案例2.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    .copywriting{
        position: absolute; 
        top: 35%;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
        h3{
            font-size: 40px;
            line-height: 80px;
            letter-spacing:2px;
        }
        p{
            font-size: 16px;
        }
    }
}

.main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 90%;

    .mainItem {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 49%;
        height: 500px;
        box-shadow: 3px 5px 11px 1px rgba(204, 204, 204, 0.2);
        border-radius: 21px;
        padding: 40px 50px;
        box-sizing: border-box;
        text-align: left;

        h2 {
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #202020;
            line-height: 30px;
        }

        p {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #717171;
            line-height: 30px;
        }

        img {
            align-self: center;
            width: 310px;
            height: 180px;
        }

        .el-button {
            align-self: center;
            width: 180px;
            height: 50px;
            border: 2px solid #FD5207;
            border-radius: 10px;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FB5604;
        }
    }
}
</style>
