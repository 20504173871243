<template>
<div>
    <div class="formwork">
        <Nav />
        <!-- 上方海报 -->
        <div class="poster bg1">
            <div class="back" @click="back">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/返回.png" alt="">
                <h2>
                    返回案例
                </h2>
            </div>
            <el-button @click="consult">立即咨询</el-button>
        </div>
        <div class="main">
            <div class="bground">
                <h2>项目背景</h2>
                <p> 为某市定制化开发人口、法人、空间地理三大基础库，通过单位原始数据汇聚，标准化治理后进行分类形成各个标准基础信息库，向用户提供标准数据目录、标准数据校验和查询服务，并针对基础信息库数据进行统计分析实现可视化的展现，为用户决策提供数据支撑。</p>
            </div>
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <div class="painPoint">
                <h2>项目痛点</h2>
                <p> 我国宏观经济管理的发展趋势是逐步实现科学化、民主化、法制化。这是建立和完善社会主义市场经济体制的要求，也是提高宏观经济管理水平和效率、促进国民经济持续、稳定、健康发展的需要。这对宏观经济管理信息系统的建设提出了更高的要求。</p>
            </div>
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <!-- -- -->
            <div class="solve">
                <h2>解决方案</h2>
                <p> 以宏观经济数据为主体，通过大数据技术，以各级各类普查资料、政府综合统计资料、部门行业登记资料、社会经济调查资料等为基础。
                    <br>
                    对本地区经济、社会、人口、科技和环境资源等基础性信息进行有计划地收集、整合、存储和管理。
                    <br>
                    形成衡量宏观经济的相关指标数据库，有效地实现信息资源的共享与交换和对政府信息资源的整合。</p>
                <div class="list">
                    <div>
                        <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/点.png" alt="">
                        <h3>系统功能</h3>
                    </div>
                    <ul>
                        <li>指标、维度维护:指标和维度是数据仓库的基础，可分层次维护。</li>
                        <li>可定制数据导人:针对不同源的历史数据可定制模板，导人数据到数据仓库中。</li>
                        <li>可定制的报表模板:可定制报表表式、汇总公式，并可对计算结果修改，计算结果可保存成文件，同时将数据保存到数据仓库中。</li>
                        <li>可定制的主题:针对数据查询、分析定制主题，将数据该主题的指标和维度组织在一起， 形成数据集市。</li>
                        <li>多维查询、分析:由于数据库采用数据仓库结构，所以可通过主题，或直接对汇总后的综合数据进行多维查询;通过选择指标和维度查询数据，并支持切片和旋转.并可对查询结果再进行-元或多元线 性回归分析。询;通过选择指标和维度查询数据，并支持切片和旋转.并可对查询结果再进行-元或多元线性回归分析</li>
                    </ul>
                </div>
                <img class="computer" src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/宏观经济库.png" alt="">
            </div>
            <div class="achievement">
                <h2>项目成就</h2>
                <p> 建成后的系统将能够实现宏观经济管理部门之间的互联互通和信息共享，促进宏观经济管理部门间的业务协同与互动，大大提高宏观经济管理决策水平。</p>
            </div>
        </div>
    </div>

    <!-- <Side /> -->


    <Footer ref="Footer" />
</div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Nav from "@/components/nav.vue";
import Side from "@/components/side.vue";
export default {
    components: {
        Footer,
        Nav,
        Side
    },
    mounted() {
        this.showWhat = sessionStorage.getItem("id")
        window.scrollTo(0, 0)
    },
    data() {
        return {
            showWhat: null
        }
    },
    methods: {
        back() {
            this.$router.push("/case")
        },
        consult() {
            this.$refs.Footer.checkShow(2)
        }
    }
}
</script>

<style lang="scss" scoped>
.formwork {
    display: flex;
    flex-direction: column;
    align-items: center;
}

//   上方海报
.poster {
    position: relative;
    width: 100%;
    height: 620px;
    background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/经济库banner.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-bottom: 50px;

    .back {
        display: flex;
        align-items: center;
        padding: 20px 0 0 150px;
        cursor: pointer;
    }

    img {
        width: 30px;
        height: 30px;
        font-size: 50px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 55px;
        margin-right: 10px;
    }

    h2 {
        font-size: 29px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 55px;
    }

    .el-button {
        position: absolute;
        top: 500px;
        left: 180px;
        width: 203px;
        height: 50px;
        background: linear-gradient(0deg, #F28E26, #FD644F);
        font-size: 25px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
        border-radius: 40px;
        border: none;
    }
}

.main {
    width: 90%;

    >div {
        text-align: left;
        margin-bottom: 110px;

        h2 {
            padding-right: 50px;
            box-sizing: border-box;
            text-align: center;
            width: 260px;
            height: 80px;
            background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/框.png);
            background-size: 100%;
            background-repeat: no-repeat;
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #202020;
            line-height: 80px;
            margin-bottom: 40px;
        }

        p {
            width: 65%;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 31px;
            padding-left: 50px;
            box-sizing: border-box;
        }
    }

    .list {
        margin-top: 45px;

        div {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            img {
                width: 30px;
                height: 30px;
                margin-right: 10px;
            }

            h3 {
                padding-top: 5px;
                font-size: 21px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #202020;
                line-height: 30px;
                box-sizing: border-box;
            }

        }

        ul {
            padding-left: 60px;

            li {
                width: 50%;
                list-style-type: decimal;
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #666666;
                line-height: 33px;
            }
        }

    }

    .computer {
        width: 530px;
        height: 434px;
        margin: 30px 0 0 60px;
    }
}
</style>
