var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fireControl"},[_c('Nav'),_c('div',{staticClass:"poster"},[_c('div',{staticClass:"introduce"},[_c('h2',[_vm._v("智慧消防解决方案")]),_c('p',[_vm._v("通过对云计算、物联网、大数据、GIS等现代信息技术的综合利用，大力创新消防管理模式，开展智慧防控、作战、执法和管理，对执法工作的系统化管理以及对管理运营的精细化管理。")]),_c('el-button',{attrs:{"type":""},on:{"click":_vm.consult}},[_vm._v("立即咨询")])],1)]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('Footer',{ref:"Footer"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Characteristics"},[_c('h2',[_vm._v("消防物联云平台特点")]),_c('p',[_vm._v("平台具有发现警情早、告警流程简单、处理警情自动化、自动通知火警所及周边人员撤离等功能，实现小微场所火灾事故的早发早报警、早扑灭，全面解决小微场所消防监督难点。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ChaBox"},[_c('div',{staticClass:"ChaBoxmini"},[_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/消防-速度.png","alt":""}}),_c('h4',[_vm._v("速度快")]),_c('p',[_vm._v("烟感报警检测，用电故障探测，急速推送，及时报警，及时确认")])]),_c('div',{staticClass:"ChaBoxmini"},[_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/消防-多途径.png","alt":""}}),_c('h4',[_vm._v("多途径")]),_c('p',[_vm._v("平台通过自动语言、短信、APP推送等多种方式及时将火灾告警信息通知各类用户和监护人员。")])]),_c('div',{staticClass:"ChaBoxmini"},[_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/消防-国形化.png","alt":""}}),_c('h4',[_vm._v("国形化")]),_c('p',[_vm._v("各国图文并茂的展示模式的帮助监督人员，进行查询、统计警情、场所、网关探测器的各类信息。")])]),_c('div',{staticClass:"ChaBoxmini"},[_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/消防-多集成.png","alt":""}}),_c('h4',[_vm._v("多集成")]),_c('p',[_vm._v("系统集成了无线物联网技术、移动互联网技术、具有技术先进、预警实时、稳定可靠等优势。")])]),_c('div',{staticClass:"ChaBoxmini"},[_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/消防-多接口.png","alt":""}}),_c('h4',[_vm._v("多接口")]),_c('p',[_vm._v("提供数据AP接口(维保接口、接处警接口、智慧消防接口、119报警中心接口综合平台接口)。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Characteristics"},[_c('h2',[_vm._v("平台多途径推送火情及时告警")]),_c('p',[_vm._v("平台通过电话自动语音、短信、APP推送及时将火灾告警信息发送至小微业主和消防管理员，小微业主可以设置最多五个的通知联系人，火警发生时将同时发送短信、轮播语音通知给相关联系人。火警通知第一时间会在管理监控平合弹窗提醒，监控人员可以第一时间通过平台查找联系业主确认火情。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/消防-推送火情.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Characteristics"},[_c('h2',[_vm._v("重点部署场所")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Focus"},[_c('div',{staticClass:"imgbox"},[_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/消防-九小.png","alt":""}}),_c('p',[_vm._v("九小场所")])]),_c('div',{staticClass:"imgbox"},[_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/消防-人员密集.png","alt":""}}),_c('p',[_vm._v("人员密集场所")])]),_c('div',{staticClass:"imgbox"},[_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/消防-易燃易爆.png","alt":""}}),_c('p',[_vm._v("易燃易爆场所")])]),_c('div',{staticClass:"imgbox"},[_c('img',{attrs:{"src":"http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/消防-劳动密集.png","alt":""}}),_c('p',[_vm._v("劳动密集场所")])])])
}]

export { render, staticRenderFns }